import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useLocation } from "react-router-dom";

export const Background = () => {
  const [currentPath, setCurrentPath] = React.useState<string>("");

  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundImage: `url("${
          process.env.PUBLIC_URL + "/img/kaappikuva.png"
        }")`,
        position: "absolute",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
        zIndex: -1,
      },
      tinted: {
        filter: "grayscale(100%)",
        backgroundImage: `linear-gradient(rgb(232 232 232), rgba(249,249,249,0.9)), url("${
          process.env.PUBLIC_URL + "/img/kaappikuva.png"
        }")`,
      },
    }),
  );

  const classes = useStyles();

  if (currentPath === "/login") {
    return <div className={classes.root}></div>;
  } else if (currentPath.includes("project")) {
    return null;
  } else {
    return <div className={`${classes.root} ${classes.tinted}`}></div>;
  }
};
