import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./containers/app/App";
import { Provider } from "react-redux";
import configureStore from "./store";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import type {} from "@material-ui/lab/themeAugmentation";

const store = configureStore();
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      "sans-serif",
    ].join(","),
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiFilledInput: {
      margin: "dense",
    },
    MuiFormControl: {
      margin: "dense",
      fullWidth: true,
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: "dense",
    },
    MuiFab: {
      size: "small",
    },
    MuiTable: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
    },
    MuiToolbar: {
      variant: "dense",
    },
    MuiToggleButtonGroup: {
      size: "small",
    },
    MuiToggleButton: {
      size: "small",
    },
    MuiRadio: {
      size: "small",
    },
  },
  overrides: {
    MuiTextField: {
      root: { backgroundColor: "white" },
    },
    MuiToggleButtonGroup: {
      root: { backgroundColor: "white", marginTop: "8px", marginBottom: "4px" },
    },
    MuiTypography: {
      h1: {
        fontSize: "2rem",
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "4px 16px",
      },
    },
    MuiPaper: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#8F1F65",
    },
    secondary: {
      main: "#303030",
    },
  },
});

const render = (Component: React.FC) => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component />
      </ThemeProvider>
    </Provider>,
    document.getElementById("root"),
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./containers/app/App", () => {
    const NextApp = require("./containers/app/App").default;
    render(NextApp);
  });
}
