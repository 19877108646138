import {
  Backdrop,
  Button,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  makeStyles,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import React from "react";
import "./newUserModal.scss";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Register } from "../../models/Register";
import * as AuthService from "../../services/Auth";
import { useDispatch } from "react-redux";
import { MessageActionType, MessageLevel } from "../../reducers/Message";
import { setMessage } from "../../actions/Message";
import { UserRole } from "../../models/User";

interface ModalProps {
  modalState: boolean;
  closeModal: () => void;
}

const registerSchema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().required(),
  email: yup.string().email().optional(),
  password: yup.string().required().min(8),
  role: yup.string().required().oneOf(["admin", "factory", "retailer"]),
  company: yup.string().optional(),
  address: yup.string().optional(),
  phoneNumber: yup.string().optional(),
  businessID: yup
    .string()
    .optional()
    .when("role", { is: "retailer", then: yup.string().optional() }),
  shortCode: yup.string().required(),
  companyLevelAccess: yup.boolean().required().default(false),
});

const useStyles = makeStyles({
  errorMsg: {
    color: "red",
    fontSize: "0.75rem",
  },
  buttonsContainer: {
    marginTop: "1rem",
  },
  cancel: {
    flexGrow: 1,
    marginRight: "",
  },
  submit: {
    float: "right",
  },
});

const NewUserModal = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentRole: UserRole = useWatch({
    name: "role",
    control,
  });

  const onSubmit = async (formData: Register) => {
    const { error, errors } = await AuthService.register(formData);
    if (error) {
      dispatch(setMessage(error, MessageLevel.Error));
    } else if (errors && errors.length) {
      for (const error of errors) {
        setError(error.field, {
          type: "manual",
          message: error.message,
        });
      }
    } else {
      dispatch({
        type: MessageActionType.SetMessage,
        payload: {
          message: "Käyttäjä luotu",
        },
      });

      props.closeModal();
      return;
    }

    return;
  };

  const body = (
    <Fade in={props.modalState} timeout={500}>
      <div className="Modal__Content">
        <form
          onSubmit={handleSubmit((d) => onSubmit(d as Register))}
          noValidate
        >
          <h2>Luo uusi käyttäjä</h2>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nimi"
            type="name"
            id="name"
            error={errors.name ? true : false}
            {...register("name", { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Käyttäjätunnus"
            type="username"
            id="username"
            error={errors.username ? true : false}
            {...register("username", { required: true })}
          />
          <span className={classes.errorMsg}>
            {errors.username && errors.username.message}
          </span>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sähköposti"
            type="email"
            id="email"
            error={errors.email ? true : false}
            {...register("email", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Salasana"
            type="password"
            id="password"
            error={errors.password ? true : false}
            {...register("password", { required: true })}
          />
          <FormControl style={{ minWidth: 150, paddingTop: "0.5rem" }}>
            <InputLabel
              id="role-select"
              style={{
                backgroundColor: "white",
                zIndex: 9999,
                marginLeft: "0.8rem",
              }}
            >
              Rooli *
            </InputLabel>
            <Controller
              name="role"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  error={error ? true : false}
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  <MenuItem value="admin">Ylläpitäjä</MenuItem>
                  <MenuItem value="factory">Tehdas</MenuItem>
                  <MenuItem value="retailer">Jälleenmyyjä</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Yritys"
            type="company"
            id="company"
            error={errors.company ? true : false}
            {...register("company", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Osoite"
            type="address"
            id="address"
            error={errors.address ? true : false}
            {...register("address", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Puhelinnumero"
            type="text"
            id="phoneNumber"
            error={errors.phoneNumber ? true : false}
            {...register("phoneNumber", { required: false })}
          />
          {currentRole === UserRole.RETAILER && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Y-tunnus"
              type="businessID"
              id="businessID"
              error={errors.businessID ? true : false}
              {...register("businessID", { required: false })}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Myymäläkoodi"
            type="shortCode"
            id="shortCode"
            error={errors.shortCode ? true : false}
            {...register("shortCode", { required: true })}
          />
          {currentRole === UserRole.RETAILER && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  defaultChecked={false}
                  {...register("companyLevelAccess", {
                    required: false,
                    value: false,
                  })}
                />
              }
              label="Pääsy yrityksen malleihin"
            />
          )}
          <Container disableGutters={true} className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.closeModal}
              className={classes.cancel}
            >
              Peruuta
            </Button>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Luo käyttäjä
            </Button>
          </Container>
        </form>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default NewUserModal;
