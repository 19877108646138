export enum MessageActionType {
  SetMessage = "message:setMessage",
  ClearMessage = "message:clearMessage",
}

export enum MessageLevel {
  Info = "info",
  Error = "error",
}

export interface MessageState {
  id: number;
  level: MessageLevel;
  message?: string;
}

export interface MessageAction {
  type: MessageActionType;
  id?: number;
  payload: {
    level?: MessageLevel;
    message?: string;
  };
}

const initialMessageState: MessageState = {
  id: 0,
  level: MessageLevel.Info,
};

export const MessageReducer = (
  state: MessageState = initialMessageState,
  action: MessageAction,
) => {
  switch (action.type) {
    case MessageActionType.SetMessage:
      return {
        ...state,
        id: state.id + 1,
        level: action.payload.level || MessageLevel.Info,
        message: action.payload.message,
      };
    case MessageActionType.ClearMessage:
      if (!action.id || state.id <= action.id) {
        return {
          ...state,
          message: undefined,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
