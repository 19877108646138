import {
  DataModel,
  BackgroundPlateTypes,
  DoorMaterialDirection,
  FrameInnerElementTypes,
} from "../models/DataModel";
import { ProjectStatus } from "../models/Project";

export const getProjectStatusName = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.INPRODUCTION:
      return "Tuotannossa";
    case ProjectStatus.INPROGRESS:
      return "Tarjous";
    case ProjectStatus.ORDERSENT:
      return "Tilattu";
    case ProjectStatus.TEMPLATE:
      return "Mallipohja";
  }
  return status;
};

export const getDefaultDataModel = (is_template: boolean): DataModel => {
  return {
    version: 1.0,
    drawMeasures: true,
    drawEdges: true,
    hasDoors: is_template ? false : true,
    hasFrame: true,
    drawShadows: true,
    drawDoors: true,
    recessedDoors: true,
    frame: {
      backgroundPlate: {
        type: BackgroundPlateTypes.INNER_3,
      },
      basePlate: true,
      content: [
        {
          type: FrameInnerElementTypes.OPENING,
          width: 1,
          components: [],
          locked: false,
        },
        {
          type: FrameInnerElementTypes.WALL_NORMAL,
        },
        {
          type: FrameInnerElementTypes.OPENING,
          width: 1,
          components: [],
          locked: false,
        },
      ],
      coverPlate: true,
      depth: 600,
      height: 2500,
      width: 2500,
      materials: {
        backgroundPlate: "VALKOINEN_3",
        frame: is_template ? "VAALEA_TAMMI_16" : null,
        inner: is_template ? "VAALEA_TAMMI_16" : null,
        shelf: is_template ? "VAALEA_TAMMI_16" : null,
      },
      plinth: {
        height: 48,
        legs: true,
      },
      lightPlate: false,
      lightPlateSwitch: false,
    },
    doors: {
      height: 2500,
      topWidth: 2500,
      bottomWidth: 2500,
      frameType: "T22",
      frameMaterial: "DOOR_FRAME_VALKOINEN",
      slidingDoors: [
        {
          width: 10,
          sectors: [
            {
              percent: 100,
              direction: DoorMaterialDirection.VERTICAL,
              height: 10,
              locked: false,
            },
          ],
        },
        {
          width: 10,
          sectors: [
            {
              percent: 100,
              direction: DoorMaterialDirection.VERTICAL,
              height: 10,
              locked: false,
            },
          ],
        },
        {
          width: 10,
          sectors: [
            {
              percent: 100,
              direction: DoorMaterialDirection.VERTICAL,
              height: 10,
              locked: false,
            },
          ],
        },
      ],
    },
  };
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};
