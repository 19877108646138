import {
  Backdrop,
  Button,
  Container,
  Fade,
  Modal,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import "./updateApiKeyModal.scss";
import * as AuthService from "../../services/Auth";
import { User } from "../../models/User";

interface ModalProps {
  modalState: boolean;
  closeModal: () => void;
  user: User;
}

const useStyles = makeStyles({
  errorMsg: {
    color: "red",
    fontSize: "0.75rem",
  },
  buttonsContainer: {
    marginTop: "1rem",
  },
  cancel: {
    flexGrow: 1,
    marginRight: "",
  },
  submit: {
    float: "right",
  },
});

const UpdateApiKeyModal = (props: ModalProps) => {
  const classes = useStyles();

  const [apiKey, setApikey] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const updateApiKey = async () => {
    const { error, apikey } = await AuthService.updateApiKey(props.user.id);
    if (error || !apikey) {
      setError(error ?? "API-avaimen haku epäonnistui");
    } else {
      setError(null);
      setApikey(apikey);
    }
  };

  const body = (
    <Fade in={props.modalState} timeout={500}>
      <div className="Modal__Content">
        <h2>Päivitä käyttäjän API-avain</h2>
        <div>
          {apiKey
            ? `Uusi API-avain: ${apiKey}`
            : props.user.api_id
            ? `Edellisen apiavaimen api id: ${props.user.api_id}`
            : ""}
        </div>
        {error && <div>{error}</div>}
        <Container disableGutters={true} className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.closeModal}
            className={classes.cancel}
          >
            Sulje
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => updateApiKey()}
          >
            Hae uusi API-avain
          </Button>
        </Container>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default UpdateApiKeyModal;
