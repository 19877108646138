export enum ImageCaptureType {
  FRAME_FRONT = "frame_front",
  DOORS_FRONT = "doors_front",
  PERSPECTIVE = "perspective",
  FRAME_PERSPECTIVE = "frame_perspective",
  DOORS_PERSPECTIVE = "doors_perspective",
}

export enum ImageCaptureStyle {
  VISUAL = "visual",
  SIMPLIFIED = "simplified",
  TEMPLATE = "template",
  VISUAL_FRONT = "visual_front",
}

export type ImageCapture = {
  type: ImageCaptureType;
  style: ImageCaptureStyle;
  factor: number;
  blob: Blob;
};

export type ImageCaptureUrls = {
  type: ImageCaptureType;
  style: ImageCaptureStyle;
  url: string;
};
