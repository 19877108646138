import { DataModel } from "./DataModel";
import { TemplateData } from "./Template";
import { User } from "./User";

export enum ProjectStatus {
  TEMPLATE = "template",
  INPROGRESS = "inprogress",
  ORDERSENT = "ordersent",
  INPRODUCTION = "inproduction",
}

export interface ProjectResponse {
  id: string;
  name: string;
  projectNumber: number;
  status: ProjectStatus;
  customer?: CustomerResponse;
  user: User;
  notes?: string;
  factoryNotes?: string;
  price?: number;
  clientPrice?: number;
  alv?: number;
  customerName?: string;
  retailerName?: string;
  extras?: Extra[];
  dataModel: DataModel;
  templateData?: TemplateData;
  deliveryWeek?: string;
  deliveryDirection?: string;
  deliveryTarget?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TemplateSaveRequest extends ProjectResponse {
  base64image: string;
}

export interface Extra {
  productId: string;
  price: number;
  views: ExtraView[];
}

export enum ExtraView {
  "SAW" = "saw",
  "COLLECT" = "collect",
}

export interface NewProjectRequest {
  name: string;
  customer: Partial<CustomerResponse>;
  status: ProjectStatus;
  notes?: string;
  factoryNotes?: string;
}
export interface UpdateProjectRequest {
  name: string;
  status: ProjectStatus;
  customer?: CustomerResponse;
  notes?: string;
  factoryNotes?: string;
  price?: number;
  alv?: number;
  extras?: Extra[];
  deliveryWeek?: string;
  deliveryDirection?: string;
  deliveryTarget?: string;
  dataModel: DataModel;
}

export interface CustomerResponse {
  id?: string;
  firstName: string;
  lastName: string;
  address?: string;
  customerID?: string;
  additionalDetails?: string;
  postalCode?: string;
  city?: string;
  eMail?: string;
  phoneNumber?: string;
}
