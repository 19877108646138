import {
  Container,
  Button,
  TextField,
  Grid,
  makeStyles,
  createStyles,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import "./UsersPage.scss";
import { allUsers } from "../../services/Users";
import { User, UserRole } from "../../models/User";
import {
  CellParams,
  ColDef,
  DataGrid,
  ValueFormatterParams,
} from "@material-ui/data-grid";
import NewUserModal from "../../components/newUserModal/newUserModal";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  getActiveUsers,
  getAdminUsers,
  getInactiveUsers,
  getNormalUsers,
} from "../../helpers/UserHelpers";
//import DeleteUserConfirm from "../../components/deleteUserConfirm/DeleteUserConfirm";
import EditUserModal from "../../components/editUserModal/editUserModal";
import ChangePasswordModal from "../../components/changePasswordModal/changePasswordModal";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/Message";
import { MessageLevel } from "../../reducers/Message";
import {
  EditOutlined,
  VpnKeyOutlined,
  SecurityOutlined,
} from "@material-ui/icons";
import LogoModal from "../../components/logoModal/LogoModal";
import UpdateApiKeyModal from "../../components/updateApiKeyModal/updateApiKeyModal";

const useStyles = makeStyles(() =>
  createStyles({
    headerRow: {
      height: "20px",
    },
    header: {
      flexGrow: 1,
      padding: 0,
      margin: 0,
    },
    row: {
      marginTop: "1rem",
      alignContent: "space-between",
    },
    input: {
      width: "20rem",
      maxWidth: "20rem",
      marginRight: "auto",
      flexGrow: 1,
    },
    userFilter: {
      marginLeft: "2rem",
    },
    data: {
      marginTop: "1rem",
      backgroundColor: "white",
    },
    editButton: {
      color: "",
    },
    deleteButton: {
      color: "red",
    },
    actionButton: { color: "rgb(48,48,48)" },
  }),
);

const UsersPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [logoOpen, setLogoOpen] = useState<boolean>(false);
  const [editUserOpen, setEditUserOpen] = useState<boolean>(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [updateApiKeyOpen, setUpdateApiKeyOpen] = useState<boolean>(false);
  //const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const [activeFilter, toggleActiveFilter] = useState<string>("active");
  const [userFilter, toggleUserFilter] = useState<string>("all");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(true);

  //const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [userToChangePassword, setUserToChangePassword] = useState<User | null>(
    null,
  );
  const [userToToUpdateApiKey, setUserToUpdateApiKey] = useState<User | null>(
    null,
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const getUserRoleString = (role: UserRole) => {
    switch (role) {
      case UserRole.ADMIN:
        return "Ylläpitäjä";
      case UserRole.FACTORY:
        return "Tehtaan käyttäjä";
      case UserRole.RETAILER:
        return "Jälleenmyyjä";
    }
    return "-";
  };

  const columns: ColDef[] = [
    { field: "name", headerName: "Nimi", width: 200, flex: 1 },
    { field: "shortCode", headerName: "Myymäläkoodi", width: 100, flex: 1 },
    { field: "company", headerName: "Yritys", width: 150, flex: 1 },
    { field: "username", headerName: "Käyttäjätunnus", width: 135, flex: 1 },
    {
      field: "email",
      headerName: "Sähköposti",
      width: 275,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Käyttäjärooli",
      width: 125,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        getUserRoleString(params.value as UserRole),
    },
    {
      field: "active",
      headerName: "Status",
      width: 115,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value === true ? "Aktiivinen" : "Inaktiivinen",
    },
    {
      field: "id",
      headerName: "Toiminto",
      width: 115,
      renderCell: (params: CellParams) => (
        <div>
          <IconButton
            aria-label="edit"
            onClick={(event) => {
              handleUserEditOpen(event, params.value as number);
            }}
          >
            <EditOutlined color="primary" />
          </IconButton>
          <IconButton
            aria-label="change password"
            onClick={(event) => {
              handleChangePasswordOpen(event, params.value as number);
            }}
          >
            <VpnKeyOutlined color="primary" />
          </IconButton>
          <IconButton
            aria-label="change api key"
            onClick={(event) => {
              handleUpdateApiKeyOpen(event, params.value as number);
            }}
          >
            <SecurityOutlined color="primary" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleActiveFilter = (
    event: React.MouseEvent<HTMLElement>,
    newActiveFilter: string,
  ) => {
    if (newActiveFilter !== null) {
      toggleActiveFilter(newActiveFilter);
      setFilters(newActiveFilter, userFilter, searchFilter);
    }
  };

  const handleUserFilter = (
    event: React.MouseEvent<HTMLElement>,
    newUsersFilter: string,
  ) => {
    if (newUsersFilter !== null) {
      toggleUserFilter(newUsersFilter);
      setFilters(activeFilter, newUsersFilter, searchFilter);
    }
  };

  const handleSearchFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
    setFilters(activeFilter, userFilter, event.target.value);
  };

  const setFilters = useCallback(
    (
      active: string = activeFilter,
      userRole: string = userFilter,
      search: string = searchFilter,
    ) => {
      const getUsersbyActiveFilter = (usersArr: User[], active: string) => {
        if (active === "active") {
          return getActiveUsers(usersArr);
        } else {
          return getInactiveUsers(usersArr);
        }
      };

      switch (userRole) {
        case "all": {
          const activeFiltered = getUsersbyActiveFilter(users, active);
          const filtered = getUsersBySearch(activeFiltered, search);
          setFilteredUsers(filtered);
          break;
        }
        case "users": {
          const normalUsers = getNormalUsers(users);
          const activeFiltered = getUsersbyActiveFilter(normalUsers, active);
          const filtered = getUsersBySearch(activeFiltered, search);
          setFilteredUsers(filtered);
          break;
        }
        case "admins": {
          const adminUsers = getAdminUsers(users);
          const activeFiltered = getUsersbyActiveFilter(adminUsers, active);
          const filtered = getUsersBySearch(activeFiltered, search);
          setFilteredUsers(filtered);
          break;
        }
      }
    },
    [activeFilter, userFilter, searchFilter, users],
  );

  const handleUserEditClose = () => {
    setUserToEdit(null);
    setEditUserOpen(false);
    setRefresh(true);
  };

  const handleUserEditOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    const user = filteredUsers.filter((user: User) => {
      return user.id === id;
    });
    setUserToEdit(user[0]);
    setEditUserOpen(true);
  };

  const handleChangePasswordClose = () => {
    setUserToChangePassword(null);
    setChangePasswordOpen(false);
    setRefresh(true);
  };

  const handleChangePasswordOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    const user = filteredUsers.filter((user: User) => {
      return user.id === id;
    });
    setUserToChangePassword(user[0]);
    setChangePasswordOpen(true);
  };

  const handleUpdateApiKeyClose = () => {
    setUserToUpdateApiKey(null);
    setUpdateApiKeyOpen(false);
  };

  const handleUpdateApiKeyOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    const user = filteredUsers.filter((user: User) => {
      return user.id === id;
    });
    setUserToUpdateApiKey(user[0]);
    setUpdateApiKeyOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRefresh(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLogoClose = () => {
    setLogoOpen(false);
  };

  const handleLogoOpen = () => {
    setLogoOpen(true);
  };

  const getUsersBySearch = (usersArr: User[], word: string) => {
    return usersArr.filter((user: User) => {
      return user.name.toLowerCase().includes(word.toLowerCase());
    });
  };

  useEffect(() => {
    if (refresh === true) {
      const fetchUsers = async () => {
        try {
          const userData = await allUsers();

          setUsers(userData);
        } catch (err: any) {
          setUsers([]);
          dispatch(
            setMessage(
              `Käyttäjien haku epäonnistui: ${err.message}`,
              MessageLevel.Error,
            ),
          );
        }
        setFilters();
        setRefresh(false);
      };

      fetchUsers();
    }
  }, [refresh, setFilters, dispatch]);

  return (
    <div className="Users">
      <Container maxWidth="lg">
        <NewUserModal modalState={open} closeModal={handleClose} />
        <LogoModal
          users={users}
          modalState={logoOpen}
          closeModal={handleLogoClose}
        />
        {userToEdit && (
          <EditUserModal
            modalState={editUserOpen}
            closeModal={handleUserEditClose}
            user={userToEdit}
          />
        )}
        {userToChangePassword && (
          <ChangePasswordModal
            modalState={changePasswordOpen}
            closeModal={handleChangePasswordClose}
            user={userToChangePassword}
          />
        )}
        {userToToUpdateApiKey && (
          <UpdateApiKeyModal
            modalState={updateApiKeyOpen}
            closeModal={handleUpdateApiKeyClose}
            user={userToToUpdateApiKey}
          />
        )}
        <Grid container>
          <Grid container>
            <Typography variant="h1" component="h1" className={classes.header}>
              KÄYTTÄJÄT
            </Typography>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogoOpen}
              >
                + Aseta logo
              </Button>
            </Box>
            <Box ml={2}>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                + Uusi käyttäjä
              </Button>
            </Box>
          </Grid>
          <Grid container className={classes.row}>
            <TextField
              className={classes.input}
              variant="outlined"
              placeholder="Hae"
              value={searchFilter}
              onChange={handleSearchFilter}
            />
            <ToggleButtonGroup
              value={activeFilter}
              defaultValue={activeFilter}
              onChange={handleActiveFilter}
              color="primary"
              exclusive
            >
              <ToggleButton value="active">Aktiiviset</ToggleButton>
              <ToggleButton value="inactive">Inaktiiviset</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              className={classes.userFilter}
              value={userFilter}
              defaultValue={userFilter}
              onChange={handleUserFilter}
              exclusive
            >
              <ToggleButton value="all">Kaikki</ToggleButton>
              <ToggleButton value="users">käyttäjät</ToggleButton>
              <ToggleButton value="admins">Ylläpitäjät</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        <DataGrid
          className={classes.data}
          rows={filteredUsers}
          columns={columns}
          pageSize={20}
          autoHeight={true}
          disableColumnSelector={true}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Container>
    </div>
  );
};

export default UsersPage;
