import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { MessageActionType, MessageLevel } from "../../reducers/Message";
import { ProjectResponse } from "../../models/Project";
import { cloneProject } from "../../services/Projects";

interface ConfirmProps {
  confirmState: boolean;
  closeConfirm: () => void;
  project: ProjectResponse | null;
  finalizeClone: (projectNumber: number) => void;
}

//const useStyles = makeStyles(() => createStyles({}));

const CloneProjectConfirm = (props: ConfirmProps) => {
  //const classes = useStyles();
  const dispatch = useDispatch();

  const handleClone = async () => {
    if (props.project) {
      const cloned = await cloneProject(props.project?.projectNumber);

      if (cloned) {
        props.finalizeClone(cloned.projectNumber);
        props.closeConfirm();
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: "Projekti kopioitu!",
          },
        });
      } else {
        props.closeConfirm();
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: "Projektia ei voitu kopioida!",
            level: MessageLevel.Error,
          },
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        open={props.confirmState}
        onClose={props.closeConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Kopioi projekti</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Haluatko varmasti kopioida projektin ${
              props.project?.projectNumber ?? ""
            }: ${props.project?.name ?? "-"}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeConfirm} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleClone} autoFocus>
            Kopioi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CloneProjectConfirm;
