import {
  FormControl,
  ButtonGroup,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { ComponentParams, FrameComponent } from "../../models/DataModel";
import { SideBarSectorItem } from "../../containers/sideBarSectorItem/SideBarSectorItem";
import { Component, ComponentParamDefinition } from "../../models/Engine";

const paramTranslations: { [key: string]: string } = {
  extraHeightMm: "Lisäkorkeus (mm)",
  wallAlignment: "Kiinnityspuoli",
  supportBarWallAlignment: "Lisätangon puoli",
  supportBarPosition: "Tukitangon sijainti (%)",
  secondBarPosition: "Lisätangon korkeus (%)",
  left: "Vasen",
  right: "Oikea",
};

export const OpeningComponent = (props: {
  component: FrameComponent;
  title: string;
  onChange: (reorder?: boolean) => void;
  onRemove: () => void;
  onAdd: () => void;
  canEdit: boolean;
  contentIndex: number;
  index: number;
  availableComponents?: Component[];
  currentComponent?: Component;
  availableHoleHeights: number[];
  isAvailableComponent: boolean;
  error: boolean;
}): ReactElement | null => {
  const {
    component,
    currentComponent,
    availableComponents,
    availableHoleHeights,
    isAvailableComponent,
  } = props;

  const [, setRefresh] = useState({});

  if (!currentComponent || !availableComponents || !availableHoleHeights)
    return null;

  const changeComponent = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const newModelID = event.target.value as string;

    if (newModelID !== currentComponent.modelID) {
      const newComponent = availableComponents.find(
        (c) => c.modelID === newModelID,
      );
      if (newComponent) {
        const oldComponentData = availableComponents.find(
          (c) => c.modelID === component.modelID,
        );
        component.modelID = newComponent?.modelID;
        updateChangedComponent(
          newComponent,
          component,
          oldComponentData,
          availableHoleHeights,
        );
      }
      props.onChange();
      setRefresh({});
    }
  };

  const getParameterTranslation = (key: string) => {
    return paramTranslations[key] || key;
  };

  const updateParameter = (
    key: string,
    def: ComponentParamDefinition,
    value: string,
  ) => {
    if (!currentComponent) return;
    if (!component.params) {
      updateChangedComponent(currentComponent, component);
    }

    (component.params as ComponentParams)[key] =
      def.type === "number" ? parseInt(value) : value;

    props.onChange();
    setRefresh({});
  };

  const canMoveUp =
    component.holeHeight <
    availableHoleHeights[availableHoleHeights.length - 1];
  const canMoveDown = component.holeHeight > availableHoleHeights[0];

  const moveUp = () => {
    const index = availableHoleHeights.findIndex(
      (hh: number) => hh > component.holeHeight,
    );
    if (index >= 0) {
      component.holeHeight = availableHoleHeights[index];
    }
  };
  const moveDown = () => {
    let index = -1;

    for (let i = 0; i < availableHoleHeights.length; i++) {
      if (availableHoleHeights[i] < component.holeHeight) {
        index = i;
      }
      if (availableHoleHeights[i] >= component.holeHeight) {
        break;
      }
    }

    if (index >= 0) {
      component.holeHeight = availableHoleHeights[index];
    }
  };

  return (
    <SideBarSectorItem
      label={props.title}
      disabled={!props.canEdit}
      onRemove={props.onRemove}
      onAdd={props.onAdd}
    >
      <FormControl component="fieldset">
        <FormControl variant="outlined">
          <Select
            disabled={!props.canEdit}
            name={`component_${props.contentIndex}_${props.index}_modelID`}
            value={props.component.modelID}
            onChange={changeComponent}
          >
            <MenuItem
              disabled={!isAvailableComponent}
              value={currentComponent.modelID}
              key={-1}
            >
              {currentComponent.name}
            </MenuItem>
            {availableComponents.map(({ name, modelID }, index) => (
              <MenuItem
                disabled={
                  component.modelID === modelID && !isAvailableComponent
                }
                value={modelID}
                key={index}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonGroup fullWidth variant="outlined" color="primary">
          <Button
            disabled={!canMoveDown || !props.canEdit}
            onClick={() => {
              moveDown();
              props.onChange(true);
            }}
            className="buttonGroupButtonSmall"
          >
            -
          </Button>
          <Select
            disabled={!props.canEdit}
            name={`component_${props.contentIndex}_${props.index}_holeHeight`}
            value={props.component.holeHeight}
            onChange={(event) => {
              component.holeHeight = parseInt(event.target.value as string);
              props.onChange(true);
            }}
          >
            {availableHoleHeights.reduce(
              (out: ReactElement[], height, index) => {
                out.unshift(
                  <MenuItem
                    disabled={!isAvailableComponent}
                    value={height}
                    key={index}
                  >
                    {height} mm
                  </MenuItem>,
                );
                return out;
              },
              [],
            )}
          </Select>
          <Button
            disabled={!canMoveUp || !props.canEdit}
            onClick={() => {
              moveUp();
              props.onChange(true);
            }}
            className="buttonGroupButtonSmall"
          >
            +
          </Button>
        </ButtonGroup>
        {Object.keys(currentComponent.params).map((key, index) => {
          const paramDef = currentComponent.params[key];
          if (paramDef.options && paramDef.options.length) {
            return (
              <FormControl variant="outlined" key={index}>
                <InputLabel>{getParameterTranslation(key)}</InputLabel>
                <Select
                  disabled={!props.canEdit}
                  name={`component_${props.contentIndex}_${props.index}_holeHeight`}
                  value={props.component.params && props.component.params[key]}
                  onChange={(event) =>
                    updateParameter(key, paramDef, event.target.value as string)
                  }
                >
                  {(paramDef.options as []).map(
                    (option: string | number, index) => (
                      <MenuItem
                        disabled={!isAvailableComponent}
                        value={option}
                        key={index}
                      >
                        {paramDef.type === "string"
                          ? getParameterTranslation(`${option}`)
                          : option}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            );
          }

          return (
            <TextField
              title={key}
              name={`component_${props.contentIndex}_${props.index}_holeHeight`}
              disabled={!props.canEdit}
              value={props.component.holeHeight}
              onChange={(event) =>
                updateParameter(key, paramDef, event.target.value as string)
              }
            />
          );
        })}
      </FormControl>
    </SideBarSectorItem>
  );
};

function updateChangedComponent(
  newComponentData: Component,
  component: FrameComponent,
  oldComponentData?: Component,
  availableHoleHeights?: number[],
) {
  if (!newComponentData.params) {
    delete component.params;
  } else {
    component.params = Object.keys(newComponentData.params).reduce(
      (out: ComponentParams, paramName: string) => {
        const param = newComponentData.params[paramName];
        out[paramName] = param.default;
        return out;
      },
      {},
    );
  }

  //Change holeheight to match new holeheight offset
  if (
    oldComponentData &&
    newComponentData &&
    availableHoleHeights &&
    availableHoleHeights.length > 1
  ) {
    const targetHoleheight =
      component.holeHeight +
      oldComponentData.minMarginTop -
      newComponentData.minMarginTop;
    if (component.holeHeight !== targetHoleheight) {
      const holeHeightStep = availableHoleHeights[1] - availableHoleHeights[0];
      for (const availableHoleHeight of availableHoleHeights) {
        if (
          Math.abs(targetHoleheight - availableHoleHeight) <
          holeHeightStep / 2
        ) {
          component.holeHeight = availableHoleHeight;
          break;
        }
      }
    }
  }
}
