import { Box } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React from "react";

type ErrorDisplayProps = {
  text: string;
  className?: string;
};

export const InfoText = (props: ErrorDisplayProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      color="darkorange"
      className={props.className}
    >
      <Info />
      <Box paddingLeft="10px">{props.text}</Box>
    </Box>
  );
};
