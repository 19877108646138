import { LoginResponse } from "../models/Login";
import { User } from "../models/User";

export enum LoginState {
  CheckLogin = "CheckLogin",
  LoggedOut = "LoggedOut",
  LoggedIn = "LoggedIn",
  LoggingIn = "LoggingIn",
  LoggingOut = "LoggingOut",
}

export type UserState = User & Pick<LoginResponse, "accessToken">;

export interface AuthState {
  loginState: LoginState;
  user?: UserState;
}

export enum AuthActionType {
  Login = "auth:login",
  LoggingIn = "auth:loggingIn",
  Logout = "auth:logout",
  LoggingOut = "auth:loggingOut",
}

export interface AuthAction {
  type: AuthActionType;
  payload: {
    user?: UserState;
  };
}

const AuthInitState = (): AuthState => {
  return {
    loginState: LoginState.CheckLogin, //on reload always check if user is already logged in with jwt cookie
    user: undefined,
  };
};

export const AuthReducer = (
  state: AuthState = AuthInitState(),
  action: AuthAction,
) => {
  switch (action.type) {
    case AuthActionType.Login:
      return {
        ...state,
        user: action.payload.user,
        loginState: LoginState.LoggedIn,
      };
    case AuthActionType.LoggingIn:
      return {
        ...state,
        user: undefined,
        loginState: LoginState.LoggingIn,
      };
    case AuthActionType.Logout:
      return {
        ...state,
        user: undefined,
        loginState: LoginState.LoggedOut,
      };
    case AuthActionType.LoggingOut:
      return {
        ...state,
        loginState: LoginState.LoggingOut,
      };
    default:
      return state;
  }
};
