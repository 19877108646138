export type OrderProduct = {
  productID?: string;
  amount: number;
  width?: number;
  height?: number;
  hasPrice: boolean;
  priceDiff?: number;
  filter?: string[];
  pricingAmount?: number;
  pricingWidth?: number;
  pricingHeight?: number;
  weight?: number;
  subProducts?: OrderProduct[];

  //Product data
  name?: string;
  material?: string;
  price?: number;
  priceText?: string;
};

export enum PartNames {
  BACKGROUND_PLATE = "Tausta",
  FRAME_START = "Vasen päätylevy",
  FRAME_END = "Oikea päätylevy",
  COVER = "Katto",
  BASE = "Pohja",
  PLINTH = "Sokkeli",
  PLINTH_PARTS = "Palat",
  INNER_WALL = "Välisivu",
  T_SHELF = "Vaakavälisivu",
  LOOSE_SHELF = "Hylly",
  FIXED_SHELF = "Kiinteä hylly",
  HALFFIXED_SHELF = "Puolisyvähylly",
  YLAVALISIVU = "Ylävälisivu",
  DRAWER_FRONTPLATE = "Etulauta",
  DRAWER_BACKPLATE = "Pääty",
  DRAWER_BASEPLATE = "Pohja",
  DRAWER_SIDEPLATE = "Sivu",
  DRAWER_RAIL_SOFT = "Hidastava Kisko",
  DRAWER_RAIL_PUSH = "Ponnahtava Kisko",
  EURORUUVI = "Euroruuvi",
  UPPOKANTA_15 = "15mm uppokanta",
  UPPOKANTA_27 = "27mm uppokanta",
  KUPUKANTA_16 = "16mm kupukanta",
  KUPUKANTA_45 = "45mm kupukanta",
  PITKA_VASTARUUVI = "Pitkä vastaruuvi",
  KULMARAUTA = "Kulmarauta",
  TAUSTANAULA = "Taustanaula",
  PEITENASTA = "Säätöjalan peitenasta",
}
