import { combineReducers } from "redux";
import { AuthReducer, AuthState } from "./Auth";
import { MessageReducer, MessageState } from "./Message";
import { ProjectReducer, ProjectState } from "./Project";

export interface AppState {
  auth: AuthState;
  message: MessageState;
  project: ProjectState;
}

const rootReducer = combineReducers({
  auth: AuthReducer,
  message: MessageReducer,
  project: ProjectReducer,
});

export default rootReducer;
