import API from "../config/axios";

export async function uploadCompanyLogo(
  company: string,
  file: File,
): Promise<boolean> {
  var formData = new FormData();
  formData.append("company", company);
  formData.append("image", file);
  const response = await API.post("/logos/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status === 200) {
    return true;
  }
  return false;
}
