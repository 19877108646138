import { yupResolver } from "@hookform/resolvers/yup";
import {
  Backdrop,
  Button,
  Checkbox,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { User, UserRole } from "../../models/User";
import * as yup from "yup";
import * as UserService from "../../services/Users";
import { MessageActionType, MessageLevel } from "../../reducers/Message";
import { AppState } from "../../reducers";

interface EditModalProps {
  modalState: boolean;
  closeModal: () => void;
  user: User;
}

const editUserSchema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().required(),
  email: yup.string().email().optional(),
  role: yup.string().required().oneOf(["admin", "factory", "retailer"]),
  company: yup.string().optional(),
  address: yup.string().optional(),
  phoneNumber: yup.string().optional(),
  businessID: yup
    .string()
    .optional()
    .when("role", { is: "retailer", then: yup.string().optional() }),
  shortCode: yup.string().required(),
  companyLevelAccess: yup.boolean().required().default(false),
  active: yup.boolean().required().default(true),
});

const useStyles = makeStyles({
  errorMsg: {
    color: "red",
    fontSize: "0.75rem",
  },
  buttonsContainer: {
    marginTop: "1rem",
  },
  cancel: {
    flexGrow: 1,
    marginRight: "",
  },
  submit: {
    float: "right",
  },
});

const EditUserModal = (props: EditModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(editUserSchema),
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user);
  const currentRole: UserRole = useWatch({
    name: "role",
    control,
    defaultValue: props.user.role,
  });

  const onSubmit = async (formData: Partial<User>) => {
    if (props.user.id) {
      try {
        switch (await UserService.editUser(props.user.id, formData)) {
          case true:
            dispatch({
              type: MessageActionType.SetMessage,
              payload: {
                message: "Käyttäjä tallennettiin!",
              },
            });
            props.closeModal();
            break;
          case false:
            dispatch({
              type: MessageActionType.SetMessage,
              payload: {
                message: "Käyttäjän tallentaminen epäonnistui!",
                level: MessageLevel.Error,
              },
            });
            break;
        }
      } catch (err: any) {
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: `Käyttäjän tallentaminen epäonnistui!: ${err.message}`,
            level: MessageLevel.Error,
          },
        });
      }
    } else {
      props.closeModal();
    }
    return;
  };

  const modalBody = (
    <Fade in={props.modalState} timeout={500}>
      <div className="Modal__Content">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h2>Muokkaa käyttäjää</h2>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nimi"
            type="text"
            id="name"
            defaultValue={props.user.name}
            error={errors.name ? true : false}
            {...register("name", { required: true })}
          />
          {props.user.username === user?.username ? (
            <TextField
              fullWidth
              type="hidden"
              id="username"
              value={props.user.username}
              {...register("username", { required: true })}
            />
          ) : (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Käyttäjätunnus"
              type="text"
              id="username"
              defaultValue={props.user.username}
              error={errors.username ? true : false}
              {...register("username", { required: true })}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sähköposti"
            type="email"
            id="email"
            defaultValue={props.user.email}
            error={errors.email ? true : false}
            {...register("email", { required: false })}
          />
          <FormControl style={{ minWidth: 150, paddingTop: "0.5rem" }}>
            <InputLabel
              id="role-select"
              style={{
                backgroundColor: "white",
                zIndex: 9999,
                marginLeft: "0.8rem",
              }}
            >
              Rooli *
            </InputLabel>
            <Controller
              name="role"
              control={control}
              defaultValue={props.user.role}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  error={error ? true : false}
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={props.user.username === user?.username}
                >
                  <MenuItem value="admin">Ylläpitäjä</MenuItem>
                  <MenuItem value="factory">Tehdas</MenuItem>
                  <MenuItem value="retailer">Jälleenmyyjä</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Yritys"
            type="string"
            id="company"
            defaultValue={props.user.company}
            error={errors.company ? true : false}
            {...register("company", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Osoite"
            type="text"
            id="address"
            defaultValue={props.user.address}
            error={errors.address ? true : false}
            {...register("address", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Puhelinnumero"
            type="text"
            id="phoneNumber"
            defaultValue={props.user.phoneNumber}
            error={errors.phoneNumber ? true : false}
            {...register("phoneNumber", { required: false })}
          />
          {currentRole === UserRole.RETAILER && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Y-tunnus"
              type="text"
              id="businessID"
              defaultValue={props.user.businessID}
              error={errors.businessID ? true : false}
              {...register("businessID", { required: false })}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Myymäläkoodi"
            type="text"
            id="shortCode"
            defaultValue={props.user.shortCode}
            error={errors.shortCode ? true : false}
            {...register("shortCode", { required: true })}
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={props.user.companyLevelAccess}
                color="primary"
                {...register("companyLevelAccess", {
                  required: false,
                })}
              />
            }
            label="Pääsy yrityksen malleihin"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.user.username === user?.username}
                defaultChecked={props.user.active}
                color="primary"
                {...register("active", {
                  required: false,
                })}
              />
            }
            label="Aktiivinen"
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={props.user.templateAccess}
                color="primary"
                {...register("templateAccess", {
                  required: false,
                })}
              />
            }
            label="Mallipohjat"
          />
          <Container disableGutters={true} className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.closeModal}
              className={classes.cancel}
            >
              Peruuta
            </Button>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Tallenna käyttäjä
            </Button>
          </Container>
        </form>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {modalBody}
    </Modal>
  );
};

export default EditUserModal;
