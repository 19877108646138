import { MaterialID } from "../DataModel";

export enum FrameMaterialDestination {
  FRAME = "frame", // Runko
  INNER = "inner", // Sisäosat
  SHELF = "shelf", // Hyllyt
  BACKGROUND_PLATE = "backgroundPlate", // Taustalevy
}

export type FrameMaterial = {
  name: string;
  //thickness: number;
  materialID: MaterialID;
};

export type AvailableFrameMaterials = {
  [key in FrameMaterialDestination]: FrameMaterial[];
};

export type AvailableDoorFrames = AvailableDoorFrame[];
export type AvailableDoorFrame = {
  name: string;
  frameType: string;
  materialID: MaterialID;
};

export type AvailableDoorSectorMaterials = AvailableDoorSectorMaterial[];
export type AvailableDoorSectorMaterial = {
  name: string;
  materialID: MaterialID;
};
