import { DataModel } from "../models/DataModel";
import { ProjectResponse } from "../models/Project";

export enum ProjectActionType {
  Fetch = "project:fetch",
  Fetched = "project:fetched",
  Update = "project:update",
  Error = "project:error",
}

export interface ProjectAction {
  type: ProjectActionType;
  error?: string;
  payload: {
    projectNumber?: number;
    project?: ProjectResponse;
    dataModelUpdate?: Partial<DataModel>;
  };
}

export enum LoadingState {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

export interface ProjectState {
  projectNumber: number | null;
  loadingState: LoadingState;
  project?: ProjectResponse;
}

const ProjectInitState = (): ProjectState => {
  return { projectNumber: null, loadingState: LoadingState.LOADING };
};

export const ProjectReducer = (
  state: ProjectState = ProjectInitState(),
  action: ProjectAction,
) => {
  switch (action.type) {
    case ProjectActionType.Fetch:
      return {
        ...state,
        loadingState: LoadingState.LOADING,
        error: action.error,
        projectNumber: action.payload.projectNumber,
        project:
          state.project?.projectNumber === action.payload.projectNumber
            ? state.project
            : undefined,
      };
    case ProjectActionType.Fetched:
      return {
        ...state,
        loadingState: LoadingState.LOADED,
        error: action.error,
        projectNumber: action.payload.projectNumber,
        project: {
          ...action.payload.project,
          dataModel: action.payload.project?.dataModel,
          extras: action.payload.project?.extras || [
            { price: "", productId: "" },
          ],
        },
      };
    case ProjectActionType.Error:
      return {
        ...state,
        loadingState: LoadingState.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};
