import { MessageActionType, MessageLevel } from "../reducers/Message";

export const setMessage = (
  message: string,
  level: MessageLevel = MessageLevel.Info,
) => ({
  type: MessageActionType.SetMessage,
  payload: { message, level },
});

export const clearMessage = (id: number) => ({
  type: MessageActionType.ClearMessage,
  id,
});
