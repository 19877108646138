import {
  Backdrop,
  Fade,
  Modal,
  Box,
  LinearProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import React from "react";
import "./exportProgressModal.scss";

interface ModalProps {
  modalState: boolean;
  value: number;
}

const ExportProgressModal = (props: ModalProps) => {
  const body = (
    <Fade in={props.modalState} timeout={500}>
      <Paper>
        <Box m={1} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" color="textPrimary">
            Luodaan PDF-dokumenttia
          </Typography>
          <Box m={1} display="flex" alignItems="center">
            <Box width="300px" mr={1}>
              <LinearProgress
                color="primary"
                variant="determinate"
                {...props}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );

  return (
    <Modal
      className="Modal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default ExportProgressModal;
