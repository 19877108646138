import {
  Button,
  Chip,
  Container,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import {
  CellParams,
  ColDef,
  DataGrid,
  PageChangeParams,
  ValueFormatterParams,
  SortModelParams,
  SortModel,
} from "@material-ui/data-grid";
import {
  DeleteOutlined,
  Search,
  FileCopyOutlined,
  EditOutlined,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setMessage } from "../../actions/Message";
import CloneProjectConfirm from "../../components/cloneProjectConfirm/CloneProjectConfirm";
import DeleteProjectConfirm from "../../components/deleteProjectConfirm/DeleteProjectConfirm";
import NewProjectModal from "../../components/newProjectModal/NewProjectModal";
import NewTemplateModal from "../../components/newTemplateModal/NewTemplateModal";
import { getProjectStatusName } from "../../helpers/ProjectHelpers";
import { ProjectResponse, ProjectStatus } from "../../models/Project";
import { AppState } from "../../reducers";
import { MessageLevel } from "../../reducers/Message";
import { allProjects } from "../../services/Projects";
import "./Home.scss";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      flexGrow: 1,
      padding: 0,
      margin: 0,
    },
    input: {
      width: "20rem",
      maxWidth: "20rem",
      marginRight: "auto",
      flexGrow: 1,
    },
    row: {
      marginTop: "1rem",
      alignContent: "space-between",
    },
    data: {
      marginTop: "1rem",
      backgroundColor: "white",
    },
    actionButton: { color: "rgb(48,48,48)" },
    [`status-${ProjectStatus.INPROGRESS}`]: {
      fontWeight: 600,
      backgroundColor: "rgba(255,207,109,0.48)",
      color: "#FF9300",
    },
    [`status-${ProjectStatus.ORDERSENT}`]: {
      fontWeight: 600,
      backgroundColor: "rgba(107,222,243,0.48)",
      color: "#3C7BC0",
    },
    [`status-${ProjectStatus.INPRODUCTION}`]: {
      fontWeight: 600,
      backgroundColor: "rgba(147,231,130,0.48)",
      color: "#3BA200",
    },
  }),
);

export const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getStatusClass = (status: ProjectStatus) => {
    const className = `status-${status}`;
    return (classes as unknown as { [key: string]: string })[className];
  };

  const [statusFilter, setStatusFilter] = useState<string>(
    localStorage.getItem("statusFilter") ?? "all",
  );
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [currentSearchFilter, setCurrentSearchFilter] =
    useState<string>(searchFilter);

  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [page, setPage] = useState<number>(
    parseInt(sessionStorage.getItem("page") || "1"),
  );
  const [sort, setSort] = useState<SortModel | undefined>(
    sessionStorage.getItem("sort_field")
      ? [
          {
            field: sessionStorage.getItem("sort_field") as string,
            sort:
              sessionStorage.getItem("sort_direction") === "asc"
                ? "asc"
                : "desc",
          },
        ]
      : undefined,
  );
  const [limit, setLimit] = useState<number>(
    parseInt(sessionStorage.getItem("limit") ?? "15"),
  );
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const searchTimeout = useRef<number | undefined>();
  const [newProjectModalOpen, setNewProjectModalOpen] =
    useState<boolean>(false);
  const [newTemplateModalOpen, setNewTemplateModalOpen] =
    useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [projectToDelete, setProjectToDelete] =
    useState<ProjectResponse | null>(null);
  const [confirmCloneOpen, setConfirmCloneOpen] = useState<boolean>(false);
  const [projectToClone, setProjectToClone] = useState<ProjectResponse | null>(
    null,
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  const user = useSelector((state: AppState) => state.auth.user);

  const openNewProjectModal = () => {
    setNewProjectModalOpen(true);
  };

  const closeNewProjectModal = () => {
    setNewProjectModalOpen(false);
  };

  const openNewTemplateModal = () => {
    setNewTemplateModalOpen(true);
  };

  const closeNewTemplateModal = () => {
    setNewTemplateModalOpen(false);
  };

  const createTableData = async (projects: ProjectResponse[]) => {
    const rows = projects.map((project: ProjectResponse) => {
      return project;
    });

    return rows;
  };

  const handleConfirmDeleteOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
  ) => {
    const project = projects.filter((project: ProjectResponse) => {
      return project.id === id;
    });

    setProjectToDelete(project[0]);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setProjectToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const handleConfirmCloneOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
  ) => {
    const project = projects.filter((project: ProjectResponse) => {
      return project.id === id;
    });

    setProjectToClone(project[0]);
    setConfirmCloneOpen(true);
  };

  const handleConfirmCloneClose = () => {
    setProjectToClone(null);
    setConfirmCloneOpen(false);
  };

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatusFilter: string,
  ) => {
    if (newStatusFilter !== null) {
      setLoading(true);
      setStatusFilter(newStatusFilter);
      localStorage.setItem("statusFilter", newStatusFilter);
    }
  };

  const handleNewProjectFinalization = (projectNumber: number) => {
    if (projectNumber !== null) {
      navigate("/projects/" + projectNumber);
    }
  };

  const handleDeleteFinalization = () => {
    setRefresh(true);
  };

  const handleCloneFinalization = (projectNumber: number) => {
    setRefresh(true);
    if (projectNumber !== null) {
      navigate("/projects/" + projectNumber);
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await allProjects(
          page - 1,
          limit,
          sort?.[0]?.field ?? undefined,
          sort?.[0]?.sort === "asc" ? 0 : 1,
          statusFilter,
          currentSearchFilter,
        );

        const rows = await createTableData(response.data);

        setRowCount(response.totalCount);
        setProjects(rows);
        setRefresh(false);
        setLoading(false);
        if (Math.ceil(response.totalCount / limit) <= response.page) {
          //Jump to first page if data is not available for requested page
          setPage(1);
        }
      } catch (err: any) {
        setRowCount(0);
        setProjects([]);
        setRefresh(false);
        setLoading(false);
        dispatch(
          setMessage(
            `Projektien haku epäonnistui: ${err.message}`,
            MessageLevel.Error,
          ),
        );
      }
    };

    fetchProjects();
  }, [page, limit, sort, statusFilter, currentSearchFilter, refresh, dispatch]);

  const columns: ColDef[] = [
    {
      field: "projectNumber",
      headerName: "Nro.",
      width: 75,
      renderCell: (params: CellParams) => (
        <Link to={"/projects/" + params.value}>{params.value}</Link>
      ),
    },
    { field: "company", headerName: "Yritys", width: 150, flex: 2 },
    {
      field: "customerName",
      headerName: "Asiakas",
      width: 175,
      flex: 2,
    },
    {
      field: "name",
      headerName: "Viite",
      width: 150,
      flex: 2,
      renderCell: (params: CellParams) => (
        <Link to={"/projects/" + params.row.projectNumber}>{params.value}</Link>
      ),
    },
    {
      field: "retailerName",
      headerName: "Myyjä",
      width: 150,
      flex: 2,
    },
    {
      field: "createdAt",
      headerName: "Pvm & klo",
      width: 130,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) => {
        let date = new Date(params.value as string);

        const day = date.getDate();
        const month = date.getMonth() + 1; // In JS the Janyary is the month number 0, so add 1
        const year = date.getFullYear();

        const hours = date.getHours();
        const minutes = date.getUTCMinutes();

        return (
          day +
          "." +
          month +
          "." +
          year +
          " " +
          hours +
          ":" +
          (minutes < 10 ? `0${minutes}` : minutes)
        );
      },
    },
    {
      field: "status",
      headerName: "Tila",
      width: 120,
      renderCell: (params: CellParams) => {
        const currentState = params.value && params.value.toString();
        return currentState ? (
          <Chip
            clickable={false}
            label={getProjectStatusName(currentState as ProjectStatus)}
            className={getStatusClass(currentState as ProjectStatus)}
          />
        ) : (
          <Chip clickable={false} label={params.value} />
        );
      },
    },
    {
      field: "id",
      headerName: "Toiminto",
      align: "right",
      width: 120,
      sortable: false,
      renderCell: (params: CellParams) => {
        const canClone = true;
        const canDelete =
          params.row.status === ProjectStatus.INPROGRESS ||
          params.row.status === ProjectStatus.TEMPLATE;

        return (
          <>
            <IconButton
              aria-label="edit"
              className={classes.actionButton}
              onClick={(event) => {
                navigate("/projects/" + params.row.projectNumber);
              }}
            >
              <EditOutlined />
            </IconButton>
            {canClone && (
              <IconButton
                aria-label="clone"
                className={classes.actionButton}
                onClick={(event) => {
                  handleConfirmCloneOpen(event, params.value as string);
                }}
              >
                <FileCopyOutlined />
              </IconButton>
            )}
            {canDelete && (
              <IconButton
                aria-label="delete"
                className={classes.actionButton}
                onClick={(event) => {
                  handleConfirmDeleteOpen(event, params.value as string);
                }}
              >
                <DeleteOutlined />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="Projects">
      <Container maxWidth="xl">
        <NewProjectModal
          modalState={newProjectModalOpen}
          closeModal={closeNewProjectModal}
          finalize={handleNewProjectFinalization}
        />
        {user?.templateAccess && (
          <NewTemplateModal
            modalState={newTemplateModalOpen}
            closeModal={closeNewTemplateModal}
            finalize={handleNewProjectFinalization}
          />
        )}
        <DeleteProjectConfirm
          confirmState={confirmDeleteOpen}
          closeConfirm={handleConfirmDeleteClose}
          project={projectToDelete}
          finalizeDelete={handleDeleteFinalization}
        />
        <CloneProjectConfirm
          confirmState={confirmCloneOpen}
          closeConfirm={handleConfirmCloneClose}
          project={projectToClone}
          finalizeClone={handleCloneFinalization}
        />
        <Grid container>
          <Grid container>
            <Typography variant="h1" component="h1" className={classes.header}>
              PROJEKTIT
            </Typography>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={openNewProjectModal}
              >
                + Uusi projekti
              </Button>
            </Box>
            {user?.templateAccess && (
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openNewTemplateModal}
                >
                  + Uusi mallipohja
                </Button>
              </Box>
            )}
          </Grid>
          <Grid container className={classes.row}>
            <TextField
              className={classes.input}
              variant="outlined"
              placeholder="Hae projektia"
              value={searchFilter}
              onChange={(params: ChangeEvent<HTMLTextAreaElement>) => {
                setSearchFilter(params.target.value);

                clearTimeout(searchTimeout.current);
                searchTimeout.current = setTimeout(() => {
                  setLoading(true);
                  setCurrentSearchFilter(params.target.value);
                }, 1000) as unknown as number;
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />

            <ToggleButtonGroup
              value={statusFilter}
              defaultValue={statusFilter}
              onChange={handleStatusFilterChange}
              exclusive
            >
              <ToggleButton value="all">Kaikki</ToggleButton>
              <ToggleButton value="inprogress">Tarjoukset</ToggleButton>
              <ToggleButton value="ordersent">Tilaukset</ToggleButton>
              <ToggleButton value="inproduction">Tuotannossa</ToggleButton>
            </ToggleButtonGroup>
            {user?.templateAccess && (
              <ToggleButtonGroup
                value={statusFilter}
                defaultValue={statusFilter}
                onChange={handleStatusFilterChange}
                exclusive
              >
                <ToggleButton value="template">Mallipohjat</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Grid>
        </Grid>
        <DataGrid
          loading={loading}
          rows={projects}
          columns={columns}
          page={page}
          pageSize={limit}
          onPageSizeChange={(params: PageChangeParams) => {
            setLoading(true);
            sessionStorage.setItem("limit", `${params.pageSize}`);
            setLimit(params.pageSize);
          }}
          onSortModelChange={(params: SortModelParams) => {
            setLoading(true);
            const _sort = params.sortModel[0];
            if (_sort) {
              sessionStorage.setItem("sort_field", _sort.field);
              sessionStorage.setItem("sort_direction", _sort.sort || "desc");
              setSort([
                {
                  field: _sort.field,
                  sort: _sort.sort,
                },
              ]);
            } else {
              setSort(undefined);
            }
          }}
          autoHeight={true}
          pagination
          className={classes.data}
          rowsPerPageOptions={[5, 10, 15, 20]}
          rowCount={rowCount}
          disableColumnSelector={true}
          onPageChange={(params: PageChangeParams) => {
            setLoading(true);
            sessionStorage.setItem("page", `${params.page}`);
            setPage(params.page);
          }}
          sortModel={sort}
          paginationMode="server"
          sortingMode="server"
        />
      </Container>
    </div>
  );
};
