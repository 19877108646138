import React, { useEffect } from "react";
import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { AccountCircle } from "@material-ui/icons";
import { AppState } from "../../reducers";
import { UserRole } from "../../models/User";

export const TopBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentPath, setCurrentPath] = React.useState<string>("");
  const user = useSelector((state: AppState) => state.auth.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    setAnchorEl(null);

    navigate("/login", { replace: true });
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "relative",
        minHeight: 60,
      },
      logo: {
        top: 0,
        width: 250,
        height: 40,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("${
          process.env.PUBLIC_URL + "/img/lakeus-logo.png"
        }")`,
      },
      username: {
        color: "white",
        fontSize: "0.9rem",
        alignSelf: "center",
      },
      menuButton: {
        color: "white",
        marginRight: theme.spacing(2),
      },
      topbarLeft: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      topbarRight: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      link: {
        color: "white",
        fontSize: "1.25rem",
        marginRight: "2rem",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
        alignItems: "center",
        display: "flex",
      },
    }),
  );

  const classes = useStyles();

  return (
    <AppBar position="static" color="secondary">
      <Toolbar className={classes.root}>
        <div className={classes.topbarLeft}>
          <Link to={"/"} className={classes.link}>
            <div className={classes.logo}></div>
          </Link>
        </div>
        <div id="topBarPortal"></div>
        <div className={classes.topbarRight}>
          {user && (
            <>
              {currentPath !== "/" && (
                <Link to={"/"} className={classes.link}>
                  PROJEKTIT
                </Link>
              )}
              {!currentPath.includes("user") && user.role === UserRole.ADMIN && (
                <Link to={"/users"} className={classes.link}>
                  KÄYTTÄJÄT
                </Link>
              )}
              <Button className={classes.username} onClick={handleClick}>
                {user.username}
              </Button>
              <IconButton
                color="inherit"
                aria-label="user-menu"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="medium"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
