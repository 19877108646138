import { Box, makeStyles } from "@material-ui/core";
import React from "react";

type ErrorDisplayProps = {
  error: string;
};

const useStyles = makeStyles({
  root: {
    color: "red",
  },
});

const ERROR_TRANSLATIONS_FI: { [key: string]: string } = {
  // -- ENGINE --
  ELEMENT_MISSING: "Komponenttia ei ole",

  // -- SCENEMANAGER --
  DATAMODEL_NOT_INITIALIZED: "Engine: Datamallia ei ole alustettu",
  SCENE_NOT_INITIALIZED: "Engine: Scene-objectia ei ole alustettu",

  // -- FRAME --
  TOO_BIG_FRAME_DEPTH: "Liian syvä runko (max 2050 mm)",
  TOO_BIG_FRAME_HEIGHT: "Liian korkea runko (max 2780 mm)",
  FRAME_MUST_BE_DEVIDED: "Runko on liian leveä. Lisää tuplaväliseinä",
  MISSALIGNED_T_WALLS: "Vaakavälisivut ovat eri korkeudella",
  WALL_MISSING: "Seinä puuttuu datamallista",
  OPENING_MISSING: "Aukko puuttuu datamallista",
  FRAME_WIDTH_MISMATCH:
    "Aukkojen leveydet eivät sovi runkoon. Avaa aukon lukitus ja korjaa leveydet.",
  OPENING_TOO_WIDE: "Liian leveä aukko",
  MATERIAL_NOT_SET: "Materiaalia ei ole asetettu",
  LIGHTPLATE_TOO_WIDE: "Valolippa on liian leveä",
  LIGHTPLATE_ON_DIVIDED_FRAME: "Valolippa jaetussa rungossa",

  // -- DOORS --
  NOT_PROPER_FRAME_FOR_RECESSED_DOORS: "Ovien materiaali ei sovi",
  NOT_PROPER_DOOR_PROPERTIES: "Karmien mitta on väärin",
  NOT_PROPER_DOOR_FRAMETYPE: "Karmien materiaalia ei löydy",
  NOT_PROPER_DOOR_SECTOR_HEIGHTS: "Sektoreiden korkeudet väärin",
  NOT_PROPER_DOOR_MIN_HEIGHT: "Ovi on liian matala",
  NOT_PROPER_DOOR_MAX_HEIGHT: "Ovi on liian korkea",
  NOT_PROPER_DOOR_SECTOR_MATERIAL_WIDTH:
    "Oven sektori on liian korkea vaakasuuntaiselle kuviolle",
};

export const ErrorDisplay = (props: ErrorDisplayProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {ERROR_TRANSLATIONS_FI[props.error] || props.error}
    </Box>
  );
};
