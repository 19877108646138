import "./ProjectPage.scss";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  LinearProgress,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { BuildOutlined, DescriptionOutlined, Undo } from "@material-ui/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataModelPanel } from "../../components/dataModelPanel/DataModelPanel";
import { ProjectInfoPanel } from "../../components/projectInfoPanel/ProjectInfoPanel";
import { useEngine } from "../../engineWrapper/engineWrapper";
import {
  ProjectResponse,
  ProjectStatus,
  TemplateSaveRequest,
} from "../../models/Project";
import { Frame, FrameComponent } from "../../models/DataModel";
import { AppState } from "../../reducers";
import { MessageActionType, MessageLevel } from "../../reducers/Message";
import { LoadingState, ProjectActionType } from "../../reducers/Project";
import * as ProjectService from "../../services/Projects";
import {
  blobToBase64,
  getDefaultDataModel,
} from "../../helpers/ProjectHelpers";
import { DataModelError, EngineError } from "../../models/Engine";
import { UserRole } from "../../models/User";
import { TopBarPortal } from "../topBarPortal/TopBarPortal";
import {
  DataModel,
  FrameInnerElementTypes,
  FrameOpening,
} from "../../models/DataModel";
import ExportProgressModal from "../../components/exportProgressModal/exportProgressModal";
import {
  ImageCaptureStyle,
  Order,
  OrderGroups,
  OrderGroupsWeights,
  OrderItems,
} from "../../models/Order";
import { exportProject } from "../../services/Projects";
import { TemplateInfoPanel } from "../../components/templateInfoPanel/TemplateInfoPanel";
import { TemplateType } from "../../models/Template";

const drawerWidth = 500;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "calc(100vh - 60px)",
      backgroundColor: "#F1F1F1",
      display: "flex",
      flexDirection: "row",
    },
    sideMenu: {
      display: "flex",
      flexDirection: "column",
      width: drawerWidth,
      height: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      overflowY: "auto",
    },
    buttonContainer: {
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      marginTop: "1rem",
    },
    menuContainer: {
      flexShrink: 0,
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
    },
    mainButtons: {
      display: "flex",
      flex: 1,
      marginTop: "auto",
      margin: "auto",
      textAlign: "center",
      height: "3rem",
      padding: "30px 0",
    },
    mainButtonsSelected: {
      borderBottom: "4px solid rgba(143,31,101,1)",
    },
    mainButtonsError: {
      paddingRight: "4px",
      borderRight: "4px solid red",
    },
    modelSpace: {
      flex: 1,
      position: "relative",
    },
    overlayContainer: {
      position: "relative",
      display: "flex",
      pointerEvents: "none",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    overlayToggles: {
      margin: "1rem",
      padding: "0 0.5rem",
      pointerEvents: "all",
    },
    overlayErrors: {
      margin: "1rem",
      padding: "0.5rem 0.5rem",
      pointerEvents: "all",
      backgroundColor: "red",
      color: "white",
    },
    price: {
      margin: "0 10px",
      fontSize: "1.2rem",
    },
    showOrderButton: {
      marginLeft: "20px",
    },
    undo: {
      marginRight: "5px",
      minWidth: "40px",
    },
  }),
);

enum ActiveProjectView {
  LOADING,
  PROJECT,
  DATAMODEL,
  TEMPLATE,
}

type ActiveProjectState = {
  activeProject?: ProjectResponse;
  activeDataModel?: DataModel;
};

export default function ProjectPage(): ReactElement | null {
  // if (props.loginState != LoginState.LoggedIn) {
  //   return <Navigate to="/login" />;
  // }
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user);

  const projectNumber = params?.id ? parseInt(params.id) : 0;
  const {
    loadingState: projectLoadingState,
    projectNumber: projectNumberState,
  } = useSelector((state: AppState) => ({
    loadingState: state.project.loadingState,
    projectNumber: state.project.projectNumber,
  }));
  const [activeProjectView, setActiveProjectView] = useState<ActiveProjectView>(
    ActiveProjectView.LOADING,
  );

  const [needFetch, setNeedFetch] = useState<boolean>(true);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isNewProject, setAsNewProject] = useState<boolean>(false);
  const [dirty, setDirty] = useState<Object | null>(null);
  const [startStatus, setStartStatus] = useState<ProjectStatus | null>(null);
  const [{ activeProject, activeDataModel }, dispatchProjectState] = useReducer<
    (
      state: ActiveProjectState,
      action: { project: ProjectResponse },
    ) => ActiveProjectState,
    ActiveProjectState
  >(
    (
      state: {
        activeProject?: ProjectResponse;
        activeDataModel?: DataModel;
      },
      action: { project: ProjectResponse },
    ) => {
      return {
        ...state,
        activeProject: action.project,
        activeDataModel: action.project.dataModel,
      };
    },
    {},
    (state) => state,
  );

  const [, setRefresh] = useState({});

  const {
    setEngineElement,
    engine,
    setEngineDataModel,
    engineDataModel,
    engineErrors,
  } = useEngine();
  const [order, setOrder] = useState<Order | null>(null);

  const [showOrder, setShowOrder] = useState<boolean>(false);

  const getOrderExtras = useCallback((): undefined | Partial<OrderItems> => {
    if (activeProject && activeProject.extras && activeProject.extras.length) {
      return {
        extras: [
          {
            name: OrderGroups.EXTRAS,
            weight: 0,
            subGroups: [],
            products: activeProject.extras.map((extra) => ({
              amount: 1,
              hasPrice: true,
              name: extra.productId,
              price: extra.price || 0,
            })),
          },
        ],
      };
    }
    return undefined;
  }, [activeProject]);

  const extendOrderExtras = useCallback(
    (order?: Order): Order | undefined => {
      if (
        order &&
        activeProject &&
        activeProject.extras &&
        activeProject.extras.length
      ) {
        const extendExtras = activeProject.extras.filter(
          (extra) => extra.views && extra.views.length,
        );

        if (extendExtras.length) {
          return {
            ...order,
            items: {
              ...order.items,
              frame: [
                ...order.items.frame,
                {
                  name: OrderGroups.EXTRAS,
                  weight: OrderGroupsWeights.EXTRAS,
                  subGroups: [],
                  products: extendExtras.map((extra) => ({
                    amount: 1,
                    hasPrice: true,
                    name: extra.productId,
                    price: extra.price || 0,
                    filter: extra.views,
                  })),
                },
              ],
            },
          };
        }
        return order;
      }
      return order;
    },
    [activeProject],
  );

  const updateOrder = useCallback(() => {
    const update = async () => {
      if (engine) {
        //TODO: refactor
        const _order = extendOrderExtras(engine.getOrder(getOrderExtras()));
        if (_order && _order.totalPrice > 0) {
          console.log("Totalprice:", _order.totalPrice);
          setOrder(_order);
          return true;
        } else {
          return false;
        }
      }
      return false;
    };
    update().then((out) => {
      if (!out) {
        setTimeout(() => update(), 5000);
      }
    });
  }, [engine, extendOrderExtras, getOrderExtras]);

  const calcComponentAmount = (
    frame: Frame | null,
    test: (c: FrameComponent) => boolean,
  ) => {
    if (!frame || !frame.content.length) {
      return 0;
    }

    return frame.content.reduce((amount: number, frame) => {
      if (
        frame.type === FrameInnerElementTypes.OPENING &&
        (frame as FrameOpening).components.some(test)
      ) {
        const addition: number = (frame as FrameOpening).components.reduce(
          (sum: number, component: FrameComponent) =>
            test(component) ? sum + 1 : sum,
          0,
        );
        console.log({ amount, addition });
        return amount + addition;
      }
      return amount;
    }, 0);
  };

  const updateTemplateData = useCallback(() => {
    if (activeProject?.templateData) {
      activeProject.templateData.shelfOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("HYLLY"),
      );
      activeProject.templateData.barOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("TANKO"),
      );
      activeProject.templateData.basketOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("KORI"),
      );
      activeProject.templateData.cleaningOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("SIIVOUS"),
      );
      activeProject.templateData.drawerOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("LAATIKKO"),
      );
      activeProject.templateData.shoeRackOpenings = calcComponentAmount(
        activeProject.dataModel.frame,
        (c) => c.modelID.includes("KENKA"),
      );
    }
  }, [activeProject]);

  const refreshEngine = useCallback(
    (markDirty: boolean = true) => {
      if (engine && activeDataModel) {
        engine.refresh();
        markDirty && setDirty({});
        updateOrder();
        if (activeProject?.status === ProjectStatus.TEMPLATE) {
          updateTemplateData();
        }
      }
    },
    [activeDataModel, engine, updateOrder, activeProject, updateTemplateData],
  );

  useEffect(() => {
    if (engineDataModel) {
      setTimeout(() => updateOrder(), 1000);
    }
  }, [engineDataModel, updateOrder]);

  const [exportProgress, setExportProgress] = useState<number | null>(null);

  const startExport = useCallback(
    (factoryExport: boolean) => {
      setExportProgress(0);
      const startExport = async (filename: string) => {
        try {
          if (engine && activeDataModel && !engineErrors.length) {
            const order = await (async () => {
              //TODO: refactor
              return extendOrderExtras(engine.getOrder(getOrderExtras()));
            })();
            if (order) {
              console.log("Totalprice for export: " + order?.totalPrice);
              setExportProgress(25);
              const captures = await engine.captureImages(
                factoryExport
                  ? ImageCaptureStyle.SIMPLIFIED
                  : ImageCaptureStyle.VISUAL,
              );
              setExportProgress(65);

              if (captures && captures.length) {
                const images = [];

                var formData = new FormData();

                for (const c of captures) {
                  const filename = `${c.type}_${c.style}`;
                  formData.append("images[]", c.blob, filename);
                  images.push({
                    type: c.type,
                    style: c.style,
                    format: c.blob.type,
                    factor: c.factor,
                    file: filename,
                  });
                }

                formData.append(
                  "data",
                  JSON.stringify({ order, images, factoryExport }),
                );

                await exportProject(formData, projectNumber).then((blob) => {
                  setExportProgress(100);
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  a.href = url;
                  a.download = filename + ".pdf";
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                });

                setExportProgress(90);
              } else {
                dispatch({
                  type: MessageActionType.SetMessage,
                  payload: {
                    message: "Kuvien laskenta epäonnistui",
                    level: MessageLevel.Error,
                  },
                });
              }
            } else {
              dispatch({
                type: MessageActionType.SetMessage,
                payload: {
                  message: "Hinnan hakeminen epäonnistui",
                  level: MessageLevel.Error,
                },
              });
            }
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: MessageActionType.SetMessage,
            payload: {
              message: "Virhe kaapiston pdf:n luonnissa",
              level: MessageLevel.Error,
            },
          });
        }
        setTimeout(() => setExportProgress(null), 1000);
      };
      const date = new Date();
      const filename = `Lakeus_Design_Projekti_${projectNumber}__${date.getDate()}_${
        date.getMonth() + 1
      }_${date.getFullYear()}`;
      startExport(filename);
    },
    [
      activeDataModel,
      dispatch,
      engine,
      engineErrors.length,
      extendOrderExtras,
      getOrderExtras,
      projectNumber,
    ],
  );

  //Function to update initial engine datamodel if not set yet
  useEffect(() => {
    if (engine && activeDataModel && activeDataModel !== engineDataModel) {
      console.info("Engine dataModel reset");
      setEngineDataModel(activeDataModel);
    }
  }, [
    activeDataModel,
    engine,
    engineDataModel,
    setEngineDataModel,
    updateOrder,
  ]);

  useEffect(() => {
    setCanEdit(
      !!activeProject &&
        activeProject.status !== ProjectStatus.INPRODUCTION &&
        (activeProject.status === ProjectStatus.INPROGRESS ||
          activeProject.status === ProjectStatus.TEMPLATE ||
          user?.role !== UserRole.RETAILER),
    );
    if (activeProject && activeProjectView === ActiveProjectView.LOADING) {
      setActiveProjectView(
        activeProject.status === ProjectStatus.TEMPLATE
          ? ActiveProjectView.TEMPLATE
          : ActiveProjectView.PROJECT,
      );
    }
  }, [
    activeProject,
    setCanEdit,
    setActiveProjectView,
    activeProjectView,
    user?.role,
  ]);

  useEffect(() => {
    const fetchProject = async () => {
      dispatch({
        type: ProjectActionType.Fetch,
        payload: {
          projectNumber,
        },
      });

      await ProjectService.getProject({ projectNumber }).then(
        (project: ProjectResponse) => {
          if (!project.dataModel) {
            setAsNewProject(true);
            project.dataModel = getDefaultDataModel(
              project.status === ProjectStatus.TEMPLATE,
            );
          }

          if (
            project.status === ProjectStatus.TEMPLATE &&
            !project.templateData
          ) {
            project.templateData = {
              doorAmounts: [2, 3, 4],
              barOpenings: 0,
              basketOpenings: 0,
              drawerOpenings: 0,
              shelfOpenings: 0,
              shoeRackOpenings: 0,
              cleaningOpenings: 0,
              type: TemplateType.ANY,
            };
          }

          setShowOrder(project.status === ProjectStatus.TEMPLATE);

          setNeedFetch(false);
          setDirty(null);
          setStartStatus(project.status);
          dispatchProjectState({ project });
          dispatch({
            type: ProjectActionType.Fetched,
            payload: {
              projectNumber,
              project,
            },
          });
        },
        (error) => {
          dispatch({
            type: MessageActionType.SetMessage,
            payload: {
              message: "Projektin hakeminen epäonnistui",
              level: MessageLevel.Error,
            },
          });
          dispatch({
            type: ProjectActionType.Error,
            error: "Projektin hakeminen epäonnistui",
            payload: { projectNumber },
          });
        },
      );
    };

    // if (projectNumberState !== projectNumber) {
    //   fetchProject();
    // }

    if (projectNumber && needFetch) {
      fetchProject();
    }
  }, [dispatch, projectNumber, projectNumberState, needFetch, setNeedFetch]);

  const canSave = useMemo(() => {
    if (!activeProject || !user || !dirty) {
      return false;
    } else {
      if (
        user.role === UserRole.RETAILER &&
        activeProject.status === ProjectStatus.INPRODUCTION
      ) {
        return false;
      } else if (
        startStatus === ProjectStatus.INPRODUCTION &&
        activeProject.status === ProjectStatus.INPRODUCTION
      ) {
        return false;
      }

      if (
        activeProject.status === ProjectStatus.INPRODUCTION &&
        (!activeProject.deliveryWeek ||
          !activeProject.deliveryDirection ||
          !activeProject.deliveryTarget)
      ) {
        return false;
      }
      return true;
    }
  }, [activeProject, user, dirty, startStatus]);

  const saveProject = useCallback(async () => {
    if (!activeProject) return;

    const save = async (
      projectToSave: ProjectResponse | TemplateSaveRequest,
    ) => {
      await ProjectService.saveProject({
        projectNumber,
        project: projectToSave,
      }).then(
        (project: ProjectResponse) => {
          setStartStatus(project.status);
          dispatchProjectState({ project });
          setDirty(null);
          setAsNewProject(false);
          dispatch({
            type: ProjectActionType.Fetched,
            payload: {
              projectNumber,
              project,
            },
          });
        },
        (error) => {
          console.error(error);
          dispatch({
            type: MessageActionType.SetMessage,
            payload: {
              message: "Projektin tallentaminen epäonnistui",
              level: MessageLevel.Error,
            },
          });
        },
      );
    };

    if (engine && activeProject.status === ProjectStatus.TEMPLATE) {
      const captures = await engine.captureImages(ImageCaptureStyle.TEMPLATE);
      if (captures.length) {
        const _project = activeProject as TemplateSaveRequest;
        _project.base64image = await blobToBase64(captures[0].blob);

        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // (a as any).style = "display: none";
        // const url = window.URL.createObjectURL(captures[0].blob);
        // a.href = url;
        // a.download = "test.png";
        // a.click();

        save(_project);
      } else {
        dispatch({
          type: MessageActionType.SetMessage,
          payload: {
            message: "Mallipohjan tallentaminen epäonnistui",
            level: MessageLevel.Error,
          },
        });
      }
    } else {
      save(activeProject);
    }
  }, [activeProject, dispatch, projectNumber, engine]);

  const renderActivePanel = (activeProjectView: ActiveProjectView) => {
    if (!user) return null;
    switch (activeProjectView) {
      case ActiveProjectView.TEMPLATE:
        return (
          <TemplateInfoPanel
            project={activeProject}
            onChange={() => {
              setDirty({});
              updateOrder();
            }}
            hasErrors={engineErrors.length > 0}
            onExport={() => startExport(false)}
            canSave={canSave}
          />
        );
      case ActiveProjectView.PROJECT:
        return (
          <ProjectInfoPanel
            project={activeProject}
            onChange={() => {
              setDirty({});
              updateOrder();
            }}
            onExport={(factoryVersion: boolean) => startExport(factoryVersion)}
            canEdit={canEdit}
            canSave={canSave}
            hasErrors={engineErrors.length > 0}
            role={user.role}
          />
        );
      case ActiveProjectView.DATAMODEL:
        return (
          <DataModelPanel
            dataModel={activeProject?.dataModel}
            templateData={activeProject?.templateData}
            engine={engine}
            refresh={refreshEngine}
            errors={engineErrors}
            canEdit={canEdit}
            isNewProject={isNewProject}
            isTemplate={activeProject?.status === ProjectStatus.TEMPLATE}
          />
        );
      default:
        return null;
    }
  };

  switch (projectLoadingState) {
    case LoadingState.LOADED:
      return (
        <div className={`${classes.root} ProjectPage`}>
          <div className={classes.sideMenu}>
            <Paper className={classes.buttonContainer}>
              {activeProject?.status === ProjectStatus.TEMPLATE ? (
                <Button
                  className={`${classes.mainButtons} ${
                    activeProjectView === ActiveProjectView.TEMPLATE
                      ? classes.mainButtonsSelected
                      : ""
                  }`}
                  onClick={() => {
                    setActiveProjectView(ActiveProjectView.TEMPLATE);
                  }}
                >
                  <DescriptionOutlined />
                  MALLINPOHJAN TIEDOT
                </Button>
              ) : (
                <Button
                  className={`${classes.mainButtons} ${
                    activeProjectView === ActiveProjectView.PROJECT
                      ? classes.mainButtonsSelected
                      : ""
                  }`}
                  onClick={() => {
                    setActiveProjectView(ActiveProjectView.PROJECT);
                  }}
                >
                  <DescriptionOutlined />
                  PROJEKTIN TIEDOT
                </Button>
              )}

              <Button
                className={`${classes.mainButtons} ${
                  activeProjectView === ActiveProjectView.DATAMODEL
                    ? classes.mainButtonsSelected
                    : ""
                } ${engineErrors.length ? classes.mainButtonsError : ""}`}
                onClick={() => {
                  setActiveProjectView(ActiveProjectView.DATAMODEL);
                }}
              >
                <BuildOutlined />
                SUUNNITELMA
              </Button>
            </Paper>
            {renderActivePanel(activeProjectView)}
          </div>
          {activeProject && (
            <main className={classes.modelSpace}>
              <div className="ProjectPage-engine" ref={setEngineElement}></div>
              <div className={classes.overlayContainer}>
                {activeDataModel && (
                  <Paper className={classes.overlayToggles}>
                    <FormControlLabel
                      key="measures"
                      control={
                        <Checkbox
                          checked={activeDataModel.drawMeasures}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            activeDataModel.drawMeasures = checked;
                            refreshEngine();
                            setRefresh({});
                          }}
                          name="drawMeasures"
                          color="primary"
                        />
                      }
                      label="Näytä mitat"
                    />
                    <FormControlLabel
                      key="edges"
                      control={
                        <Checkbox
                          checked={activeDataModel.drawEdges}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            activeDataModel.drawEdges = checked;
                            refreshEngine();
                            setRefresh({});
                          }}
                          name="drawEdges"
                          color="primary"
                        />
                      }
                      label="Näytä reunat"
                    />
                    <FormControlLabel
                      key="shadows"
                      control={
                        <Checkbox
                          checked={activeDataModel.drawShadows}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            activeDataModel.drawShadows = checked;
                            refreshEngine();
                            setRefresh({});
                          }}
                          name="drawShadows"
                          color="primary"
                        />
                      }
                      label="Näytä varjot"
                    />
                    {activeDataModel.hasDoors && (
                      <FormControlLabel
                        key="doors"
                        control={
                          <Checkbox
                            checked={activeDataModel.drawDoors}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean,
                            ) => {
                              activeDataModel.drawDoors = checked;
                              refreshEngine();
                              setRefresh({});
                            }}
                            name="drawDoors"
                            color="primary"
                          />
                        }
                        label="Näytä ovet"
                      />
                    )}
                  </Paper>
                )}
                {activeDataModel && engineErrors.length > 0 && (
                  <Tooltip
                    title={engineErrors.map(
                      (error: EngineError, index: number) => (
                        <Box key={index}>{`${error.message} ${
                          (error as DataModelError).path
                        }`}</Box>
                      ),
                    )}
                  >
                    <Paper className={classes.overlayErrors}>
                      Virheitä: {engineErrors.length}
                    </Paper>
                  </Tooltip>
                )}
              </div>
            </main>
          )}

          <TopBarPortal>
            <Box display="flex" flexDirection="row" alignItems="center">
              {canSave &&
                dirty && [
                  <Button
                    variant="contained"
                    aria-label="undo"
                    color="secondary"
                    className={classes.undo}
                    // FIXME: fix setNeedFecth functionality instead of using location reload
                    // onClick={(event) => setNeedFetch(true)}
                    onClick={(event) => window.location.reload()}
                  >
                    <Undo scale={0.5} />
                  </Button>,
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      saveProject();
                    }}
                  >
                    Tallenna muutokset
                  </Button>,
                ]}
              {order && (
                <Button
                  className={classes.showOrderButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowOrder(!showOrder);
                  }}
                >
                  {showOrder ? "Piilota hinta" : "Näytä hinta"}
                </Button>
              )}

              {order && showOrder && (
                <Box className={classes.price}>
                  <strong>Hinta:</strong> {Math.round(order.totalPrice)} € +
                  Rahti
                </Box>
              )}
              {order && showOrder && activeProject?.clientPrice && (
                <Box className={classes.price}>
                  <strong>Maksettu hinta:</strong>{" "}
                  {Math.round(activeProject?.clientPrice)} €
                </Box>
              )}
            </Box>
          </TopBarPortal>

          <ExportProgressModal
            modalState={exportProgress != null}
            value={exportProgress || 0}
          />
        </div>
      );
  }
  return <LinearProgress />;
}
