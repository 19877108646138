import API from "../config/axios";
import { User } from "../models/User";

export async function allUsers(): Promise<User[]> {
  const response = await API.get("/users");
  if (response.data) {
    return response.data;
  }
  return [];
}

export async function editUser(
  id: number,
  user: Partial<User>,
): Promise<boolean> {
  const response = await API.patch("/users/" + id, user);
  return response.status === 200;
}

export async function deleteUser(id: number): Promise<boolean> {
  const response = await API.delete("/users/" + id);

  return response.status === 200;
}
