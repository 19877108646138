export interface CommonError {
  message: EngineErrors | string;
}
export interface DataModelError extends CommonError {
  path: string | null;
}

export interface FrameWidthError extends DataModelError {
  data: {
    calculatedWidth: number;
    targetWidth: number;
  };
}

export interface DoorSizeError extends DataModelError {
  data: {
    calculatedValue: number;
    valueLimit: number;
  };
}

export type EngineError =
  | CommonError
  | DataModelError
  | DoorSizeError
  | FrameWidthError;

export enum EngineErrors {
  // -- ENGINE --
  ELEMENT_MISSING = "ELEMENT_MISSING",

  // -- SCENEMANAGER --
  DATAMODEL_NOT_INITIALIZED = "DATAMODEL_NOT_INITIALIZED",
  SCENE_NOT_INITIALIZED = "SCENE_NOT_INITIALIZED",

  // -- FRAME --
  TOO_BIG_FRAME_HEIGHT = "TOO_BIG_FRAME_HEIGHT",
  TOO_BIG_FRAME_DEPTH = "TOO_BIG_FRAME_DEPTH",
  FRAME_MUST_BE_DEVIDED = "FRAME_MUST_BE_DEVIDED",
  MISSALIGNED_T_WALLS = "MISSALIGNED_T_WALLS",
  WALL_MISSING = "WALL_MISSING",
  OPENING_MISSING = "OPENING_MISSING",
  FRAME_WIDTH_MISMATCH = "FRAME_WIDTH_MISMATCH",
  OPENING_TOO_WIDE = "OPENING_TOO_WIDE",
  MATERIAL_NOT_SET = "MATERIAL_NOT_SET",
  LIGHTPLATE_TOO_WIDE = "LIGHTPLATE_TOO_WIDE",
  LIGHTPLATE_ON_DIVIDED_FRAME = "LIGHTPLATE_ON_DIVIDED_FRAME",

  // -- DOORS --
  NOT_PROPER_FRAME_FOR_RECESSED_DOORS = "NOT_PROPER_FRAME_FOR_RECESSED_DOORS",
  NOT_PROPER_DOOR_PROPERTIES = "NOT_PROPER_DOOR_PROPERTIES",
  NOT_PROPER_DOOR_FRAMETYPE = "NOT_PROPER_DOOR_FRAMETYPE",
  NOT_PROPER_DOOR_SECTOR_HEIGHTS = "NOT_PROPER_DOOR_SECTOR_HEIGHTS",
  NOT_PROPER_DOOR_SECTOR_MATERIAL_WIDTH = "NOT_PROPER_DOOR_SECTOR_MATERIAL_WIDTH",
  NOT_PROPER_DOOR_MIN_HEIGHT = "NOT_PROPER_DOOR_MIN_HEIGHT",
  NOT_PROPER_DOOR_MAX_HEIGHT = "NOT_PROPER_DOOR_MAX_HEIGHT",
}
