import {
  NewProjectRequest,
  ProjectResponse,
  TemplateSaveRequest,
} from "../models/Project";
import API from "../config/axios";
import { PaginatedProjects } from "../models/Pagination";

export async function allProjects(
  page: number,
  limit: number,
  sortField?: string,
  sortDirection?: 0 | 1,
  status: string = "all",
  search: string = "",
): Promise<PaginatedProjects> {
  const response = await API.get("/projects", {
    timeout: 10000,
    params: {
      page: page,
      limit: limit,
      sortField: sortField,
      sortDirection: sortDirection,
      status: status,
      search: search,
    },
  });

  if (response.data) {
    return response.data;
  }
  return {
    data: [],
    page: 0,
    limit: 20,
    sortField,
    sortDirection,
    totalCount: 0,
  };
}

export async function createProject(
  projectData: NewProjectRequest,
): Promise<ProjectResponse | null> {
  try {
    const response = await API.post("/projects", projectData);
    if (response.status === 201) {
      return response.data as ProjectResponse;
    } else return null;
  } catch (err) {
    return null;
  }
}

export async function deleteProject(id: string): Promise<boolean> {
  try {
    const response = await API.delete("/projects/" + id);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function cloneProject(
  projectNumber: number,
): Promise<ProjectResponse | null> {
  try {
    const response = await API.post("/projects/clone", { projectNumber });
    if (response.status === 201) {
      return response.data as ProjectResponse;
    } else return null;
  } catch (err) {
    return null;
  }
}

export async function getProject({ projectNumber }: { projectNumber: number }) {
  const response = await API.get(`/projects/${projectNumber}`);
  if (response.status === 200) {
    return response.data as ProjectResponse;
  }
  throw Error("Error while fetching project");
}

export async function saveProject({
  projectNumber,
  project,
}: {
  projectNumber: number;
  project: ProjectResponse | TemplateSaveRequest;
}) {
  const response = await API.put(`/projects/${projectNumber}`, project);
  if (response.status === 200 && response.data && response.data.projectNumber) {
    return response.data as ProjectResponse;
  }
  throw Error("Error while fetching project");
}

export async function exportProject(formData: FormData, projectNumber: number) {
  return API.post(`/export/${projectNumber}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
    //timeout: 30000,
  }).then((response) => {
    if (response.status === 201) {
      return response.data;
    }
    throw Error(`Error while fetching pdf: ${response.status}`);
  });
}
