export enum UserRole {
  ADMIN = "admin",
  FACTORY = "factory",
  RETAILER = "retailer",
}
export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  role: UserRole;
  company: string;
  address: string;
  businessID: string;
  shortCode: string;
  phoneNumber: string;
  companyLevelAccess: boolean;
  templateAccess: boolean;
  active: boolean;
  api_id: string;
}
