import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { SideBarSector } from "../../containers/sideBarSector/SideBarSector";
import { SideBarSectorItem } from "../../containers/sideBarSectorItem/SideBarSectorItem";
import { ProjectResponse } from "../../models/Project";
import { TemplateType } from "../../models/Template";

export const TemplateInfoPanel = (props: {
  project?: ProjectResponse;
  onChange: () => void;
  onExport: () => void;
  hasErrors: boolean;
  canSave: boolean;
}) => {
  const { project, onChange } = props;
  const [, updateDataState] = useState({});

  if (!project) {
    return null;
  }

  function onDataChange<I, O>(
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
    value?: I,
    transformer?: (value: I) => O,
  ) {
    if (!event.target.name) return;
    const path = event.target.name.split(".");
    let dst = project as any;
    if (path.length > 1) {
      for (const segment of path.slice(0, path.length - 1)) {
        if (!dst[segment]) {
          return;
        }
        dst = dst[segment];
      }
    }
    dst[path[path.length - 1]] = transformer
      ? transformer(value ?? (event.target.value as I))
      : value ?? event.target.value;
    updateDataState({});
    onChange();
  }

  return (
    <>
      <SideBarSector
        defaultExpanded
        title={`Mallipohja ${project.projectNumber}`}
      >
        <SideBarSectorItem label="Mallipohjan nimi" name="name">
          <FormControl component="fieldset">
            <TextField
              variant="outlined"
              aria-label="name"
              name="name"
              value={project.name || ""}
              onChange={onDataChange}
            />
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Kohde" name="templateData.type">
          <FormControl variant="outlined">
            <Select
              name="templateData.type"
              value={project.templateData?.type || TemplateType.ANY}
              onChange={(event) => onDataChange<string, string>(event)}
            >
              <MenuItem value={TemplateType.ANY}>Kaikki</MenuItem>
              <MenuItem value={TemplateType.HALLWAY}>Eteinen</MenuItem>
              <MenuItem value={TemplateType.BEDROOM}>Makuuhuone</MenuItem>
              <MenuItem value={TemplateType.LIVINGROOM}>Olohuone</MenuItem>
              <MenuItem value={TemplateType.HOUSEKEEPING}>Kodinhoito</MenuItem>
            </Select>
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Ovien määrä" name="templateData.type">
          {[2, 3, 4, 5, 6, 7, 8].map((da: number) => (
            <FormControl variant="outlined" key={da}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.templateData?.doorAmounts.includes(da)}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => {
                      if (project.templateData) {
                        if (checked) {
                          project.templateData.doorAmounts.push(da);
                        } else {
                          project.templateData.doorAmounts = Array.from(
                            new Set(
                              project.templateData?.doorAmounts.filter(
                                (amount: number) => amount !== da,
                              ),
                            ),
                          );
                        }
                      }
                      updateDataState({});
                      onChange();
                    }}
                    color="primary"
                  />
                }
                label={da}
              />
            </FormControl>
          ))}
        </SideBarSectorItem>
        <SideBarSectorItem
          label="Lataa"
          error={
            props.hasErrors
              ? "Korjaa suunnitelman virheet ennen PDF:n lataamista"
              : undefined
          }
        >
          <FormControl component="fieldset">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.onExport()}
              disabled={props.hasErrors || props.canSave}
              title="Lataa PDF"
            >
              Lataa PDF
            </Button>
          </FormControl>
        </SideBarSectorItem>
        <SideBarSectorItem label="Tiedot">
          <div>Hyllyjä: {project.templateData?.shelfOpenings}</div>
          <div>Koreja: {project.templateData?.basketOpenings}</div>
          <div>Laatikoita: {project.templateData?.drawerOpenings}</div>
          <div>Tankoja: {project.templateData?.barOpenings}</div>
          <div>
            Siivousvälinehyllyjä: {project.templateData?.cleaningOpenings}
          </div>
          <div>Kenkähyllyjä: {project.templateData?.shoeRackOpenings}</div>
          <div>
            Leveys: {project.templateData?.minWidth ?? "N/A"} &lt;&gt;{" "}
            {project.templateData?.maxWidth ?? "N/A"}
          </div>
          <div>
            Korkeus: {project.templateData?.minHeight ?? "N/A"} &lt;&gt;{" "}
            {project.templateData?.maxHeight ?? "N/A"}
          </div>
          <div>
            Syvyys: {project.templateData?.minDepth ?? "N/A"} &lt;&gt;{" "}
            {project.templateData?.maxDepth ?? "N/A"}
          </div>
        </SideBarSectorItem>
      </SideBarSector>
    </>
  );
};
