import { yupResolver } from "@hookform/resolvers/yup";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import * as ProjectService from "../../services/Projects";
import "./NewProjectModal.scss";
import { MessageActionType, MessageLevel } from "../../reducers/Message";
import { NewProjectRequest, ProjectStatus } from "../../models/Project";
import { AppState } from "../../reducers";
import { User, UserRole } from "../../models/User";
import { allUsers } from "../../services/Users";
import { Autocomplete } from "@material-ui/lab";

interface ModalProps {
  modalState: boolean;
  closeModal: () => void;
  finalize: (projectNumber: number) => void;
}

const useStyles = makeStyles({
  errorMsg: {
    color: "red",
    fontSize: "0.75rem",
  },
  customerHeader: {
    marginBottom: 0,
  },
  buttonsContainer: {
    marginTop: "1rem",
  },
  cancel: {
    flexGrow: 1,
    marginRight: "",
  },
  submit: {
    float: "right",
  },
});

const customerSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  customerID: yup.string().optional(),
  address: yup.string().optional(),
  postalCode: yup
    .string()
    .trim()
    .matches(/^[0-9]+$/, "Vain numerot sallittuja"),
  city: yup.string().optional(),
  eMail: yup.string().email().optional(),
  phoneNumber: yup.string().optional(),
  additionalDetails: yup.string().optional(),
});

const projectSchema = yup.object().shape({
  name: yup.string().required(),
  customer: customerSchema.required(),
  userId: yup.number().optional(),
});

const NewProjectModal = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(projectSchema),
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user);
  const [users, setUsers] = useState<User[]>([]);

  const onSubmit = async (formData: NewProjectRequest) => {
    const project = await ProjectService.createProject({
      ...formData,
      status: ProjectStatus.INPROGRESS,
    });
    if (project) {
      dispatch({
        type: MessageActionType.SetMessage,
        payload: {
          message: "Projekti luotu!",
        },
      });
      props.closeModal();
      props.finalize(project.projectNumber);
    } else {
      dispatch({
        type: MessageActionType.SetMessage,
        payload: {
          message: "Projektin luonti epäonnistui!",
          level: MessageLevel.Error,
        },
      });
    }
    return;
  };

  useEffect(() => {
    if (props.modalState && user?.role !== UserRole.RETAILER) {
      const fetchUsers = async () => {
        try {
          const fetchedUsers = (await allUsers()).filter((u) => !!u.active);
          fetchedUsers.sort((a, b) => a.company.localeCompare(b.company));
          setUsers(fetchedUsers);
        } catch (err) {
          setUsers([]);
        }
      };

      fetchUsers();
    }
  }, [props.modalState, user?.role]);

  const getUserSelectionTitle = (user: User | undefined) => {
    if (user) {
      const { company, name, companyLevelAccess } = user;
      return `${company ? company + " - " : ""}${name}${
        companyLevelAccess ? " *" : ""
      }`;
    }
    return "-";
  };

  const getOptionUser = (option: number | User | undefined) => {
    if (option) {
      if (typeof option === "number") {
        option = users.find((u) => u.id === option) ?? user;
      } else {
        return option;
      }
    }
    return user;
  };

  const body = (
    <Fade in={props.modalState} timeout={500}>
      <div className="ProjectModal__Content">
        <form
          onSubmit={handleSubmit((d) => onSubmit(d as NewProjectRequest))}
          noValidate
        >
          <h2>Luo uusi projekti</h2>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Viite"
            type="text"
            id="name"
            error={errors.name ? true : false}
            {...register("name", { required: true })}
          />
          <h3 className={classes.customerHeader}>Asiakas</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Etunimi"
            type="text"
            id="customer.firstName"
            error={errors.customer?.firstName ? true : false}
            {...register("customer.firstName", { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sukunimi"
            type="text"
            id="customer.lastName"
            error={errors.customer?.lastName ? true : false}
            {...register("customer.lastName", { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Osoite"
            type="text"
            id="customer.address"
            error={errors.customer?.address ? true : false}
            multiline
            {...register("customer.address", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Postiumero"
            type="number"
            id="customer.postalCode"
            error={errors.customer?.postalCode ? true : false}
            {...register("customer.postalCode", { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Postitoimipaikka"
            type="text"
            id="customer.city"
            error={errors.customer?.city ? true : false}
            {...register("customer.city", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            defaultValue=""
            label="EMail"
            type="text"
            id="customer.eMail"
            error={errors.customer?.eMail ? true : false}
            {...register("customer.eMail", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            defaultValue=""
            label="Puhelinnumero"
            type="text"
            id="customer.phoneNumber"
            error={errors.customer?.phoneNumber ? true : false}
            {...register("customer.phoneNumber", { required: false })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Toimituksen lisätiedot"
            type="text"
            id="customer.additionalDetails"
            error={errors.customer?.additionalDetails ? true : false}
            multiline
            {...register("customer.additionalDetails", { required: false })}
          />
          {user &&
            user?.role !== UserRole.RETAILER && [
              <h3 className={classes.customerHeader} key="jmh3">
                Jälleenmyyjä
              </h3>,
              <Autocomplete
                key="jmc"
                id="userId"
                defaultValue={user}
                options={users}
                getOptionLabel={(option) =>
                  getUserSelectionTitle(getOptionUser(option))
                }
                getOptionSelected={(option, value) => {
                  return option.id === getOptionUser(value)?.id;
                }}
                autoComplete={true}
                autoSelect={true}
                placeholder={getUserSelectionTitle(user)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText="* = käyttäjällä pääsy kaikkiin yrityksen tilauksiin"
                  />
                )}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: User | null,
                ) => {
                  setValue(
                    "userId",
                    getOptionUser(value ?? user)?.id ?? user.id,
                  );
                }}
              />,
            ]}
          <Container disableGutters={true} className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.closeModal}
              className={classes.cancel}
            >
              Peruuta
            </Button>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Luo projekti
            </Button>
          </Container>
        </form>
      </div>
    </Fade>
  );

  return (
    <Modal
      className="ProjectModal"
      open={props.modalState}
      BackdropProps={{ timeout: 500 }}
      BackdropComponent={Backdrop}
    >
      {body}
    </Modal>
  );
};

export default NewProjectModal;
