import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OpeningComponent } from "../openingComponent/OpeningComponent";
import { SideBarSector } from "../../containers/sideBarSector/SideBarSector";
import { SideBarSectorItem } from "../../containers/sideBarSectorItem/SideBarSectorItem";
import { DesignEngine } from "../../engineWrapper/engineWrapper";
import {
  BackgroundPlateTypes,
  ComponentParams,
  DataModel,
  DoorMaterialDirection,
  FrameComponent,
  FrameInnerElements,
  FrameInnerElementTypes,
  FrameOpening,
  FramePartitionWallT,
  SlidingDoor,
} from "../../models/DataModel";
import {
  AvailableComponents,
  AvailableFrameMaterials,
  AvailableDoorSectorMaterials,
  AvailableHoleHeights,
  AvailableDoorFrames,
  Component,
  EngineError,
  DataModelError,
} from "../../models/Engine";
import { InfoText } from "../infoText/InfoText";
import { TemplateData } from "../../models/Template";

type UpdateRequest = {
  frameMaterials?: boolean;
  allComponents?: boolean;
  availableComponents?: boolean;
  availableHoleHeights?: boolean;
  availableDoorFrames?: boolean;
  availableDoorSectorMaterials?: boolean;
};

type EngineDataState = {
  availableFrameMaterials?: AvailableFrameMaterials;
  availableDoorSectorMaterials?: AvailableDoorSectorMaterials;
  allComponents?: Component[];
  availableComponents?: AvailableComponents;
  availableHoleHeights?: AvailableHoleHeights;
  availableDoorFrames?: AvailableDoorFrames;
};

const OPENING_WIDTH_PRESETS = [367, 467, 567, 767];

type DoorPreset = { name: string; values: number[] };
const DoorPresets: DoorPreset[] = [
  { name: "100%", values: [100] },
  { name: "60% 40%", values: [60, 40] },
  { name: "25% - 75%", values: [25, 75] },
  { name: "20% - 60% - 20%", values: [20, 60, 20] },
  { name: "25% - 50% - 25%", values: [25, 50, 25] },
  { name: "40% - 20% - 40%", values: [40, 20, 40] },
  { name: "20% - 20% - 60%", values: [20, 20, 60] },
  { name: "60% - 20% - 20%", values: [60, 20, 20] },
  { name: "50% - 50%", values: [50, 50] },
  { name: "33% - 33% - 33%", values: [33, 33, 33] },
  { name: "25% - 25% - 25% - 25%", values: [25, 25, 25, 25] },
  { name: "20% - 20% - 20% - 20% - 20%", values: [20, 20, 20, 20, 20] },
  { name: "20% - 80%", values: [20, 80] },
  { name: "40% - 60%", values: [40, 60] },
  { name: "80% - 20%", values: [80, 20] },
  { name: "75% - 25%", values: [75, 25] },
];

export const DataModelPanel = (props: {
  dataModel?: DataModel;
  templateData?: TemplateData;
  refresh: () => void;
  engine?: DesignEngine;
  errors: EngineError[];
  canEdit: boolean;
  isNewProject: boolean;
  isTemplate: boolean;
}) => {
  const { dataModel, engine, refresh, canEdit, errors } = props;

  const [dataState, updateDataState] = useState<EngineDataState>({});
  const update = useCallback(
    (update: UpdateRequest) => {
      const async_update = async () => {
        if (engine) {
          const updatedState: EngineDataState = {};

          if (update.frameMaterials) {
            await (async () => {
              if (!engine) return;
              updatedState.availableFrameMaterials =
                engine.getAvailableFrameMaterials();
            })();
          }
          if (update.allComponents) {
            await (async () => {
              if (!engine) return;
              updatedState.allComponents = engine.getAllComponents();
            })();
          }
          if (update.availableComponents) {
            await (async () => {
              if (!engine) return;
              updatedState.availableComponents =
                engine.getAvailableComponents();
            })();
          }
          if (update.availableHoleHeights) {
            await (async () => {
              if (!engine) return;
              updatedState.availableHoleHeights =
                engine.getAvailableHoleHeights();
            })();
          }
          if (update.availableDoorFrames) {
            await (async () => {
              if (!engine) return;
              updatedState.availableDoorFrames =
                engine.getAvailableDoorFrames();
            })();
          }
          if (update.availableDoorSectorMaterials) {
            await (async () => {
              if (!engine) return;
              updatedState.availableDoorSectorMaterials =
                engine.getAvailableDoorSectorMaterials();
            })();
          }

          updateDataState((state: any) => {
            return {
              ...state,
              ...updatedState,
            };
          });
        } else {
          updateDataState({});
        }
      };
      async_update();
    },
    [engine],
  );

  useEffect(() => {
    if (
      engine &&
      dataModel &&
      ((dataModel.hasFrame && !dataState.availableFrameMaterials) ||
        (dataModel.hasDoors && !dataState.availableDoorSectorMaterials))
    ) {
      update({
        frameMaterials: dataModel.hasFrame ?? true,
        allComponents: dataModel.hasFrame ?? true,
        availableComponents: dataModel.hasFrame ?? true,
        availableHoleHeights: dataModel.hasFrame ?? true,
        availableDoorFrames: dataModel.hasDoors ?? true,
        availableDoorSectorMaterials: dataModel.hasDoors ?? true,
      });
    }
  }, [
    update,
    engine,
    dataState.availableFrameMaterials,
    dataModel?.hasFrame,
    dataModel?.hasDoors,
    dataState?.availableDoorSectorMaterials,
    dataModel,
  ]);

  const calculatedInnerDepth = useMemo(() => {
    let depth = dataModel?.frame?.depth;
    if (!depth) return "N/A";
    if (dataModel?.frame?.backgroundPlate) {
      switch (dataModel?.frame?.backgroundPlate.type) {
        case BackgroundPlateTypes.OUTER_16:
          depth -= 16;
          break;
        case BackgroundPlateTypes.OUTER_3:
        case BackgroundPlateTypes.INNER_3:
          depth -= 3;
          break;
      }
    }
    if (dataModel?.recessedDoors) {
      depth -= 90;
    }

    // Inner depth is always 1mm smaller than outer depth
    depth -= 1;
    return depth;
  }, [
    dataModel?.frame?.backgroundPlate,
    dataModel?.frame?.depth,
    dataModel?.recessedDoors,
  ]);

  if (!dataModel || !engine) {
    return null;
  }

  const hasFrame = dataModel.hasFrame;
  const hasDoors = dataModel.hasDoors;

  function onDataChange<I, O>(
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
    params?: {
      transformer?: (value: I) => O;
      beforeUpdate?: () => void;
      update?: UpdateRequest;
    },
  ) {
    if (!event.target.name) return;
    const path = event.target.name.split(".");
    let dst = dataModel as any;
    if (path.length > 1) {
      for (const segment of path.slice(0, path.length - 1)) {
        if (!dst[segment]) {
          return;
        }
        dst = dst[segment];
      }
    }

    dst[path[path.length - 1]] = params?.transformer
      ? params.transformer(event.target.value as I)
      : event.target.value;

    refresh();
    if (params?.beforeUpdate) params.beforeUpdate.call(null);
    update(params?.update || {});
  }

  const getFrameType = () => {
    if (dataModel.recessedDoors && dataModel.hasDoors && dataModel.hasFrame) {
      return "recessedDoors";
    } else if (
      dataModel.recessedDoors &&
      !dataModel.hasDoors &&
      dataModel.hasFrame
    ) {
      return "recessedDoorsFrame";
    } else if (dataModel.hasDoors && dataModel.hasFrame) {
      return "externalDoors";
    } else if (dataModel.hasFrame) {
      return "frame";
    } else if (dataModel.hasDoors) {
      return "doors";
    }
    return "recessedDoors";
  };

  const changeFrameType = (event: React.ChangeEvent<{ value: unknown }>) => {
    switch (event.target.value as string) {
      case "recessedDoors":
        dataModel.hasFrame = true;
        dataModel.hasDoors = true;
        dataModel.recessedDoors = true;
        break;
      case "externalDoors":
        dataModel.hasFrame = true;
        dataModel.hasDoors = true;
        dataModel.recessedDoors = false;
        break;
      case "frame":
        dataModel.hasFrame = true;
        dataModel.hasDoors = false;
        dataModel.recessedDoors = false;
        break;
      case "recessedDoorsFrame":
        dataModel.hasFrame = true;
        dataModel.hasDoors = false;
        dataModel.recessedDoors = true;
        break;
      case "doors":
        dataModel.hasFrame = false;
        dataModel.hasDoors = true;
        dataModel.recessedDoors = false;
        break;
      default:
        break;
    }
    dataModel.drawDoors = true;
    refresh();
    update({});
  };

  const getBaseType = () => {
    if (dataModel.frame?.plinth) {
      return "plinth";
    } else if (dataModel.frame?.basePlate) {
      return "basePlate";
    }
    return "no";
  };

  const changeBaseType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const frame = dataModel.frame;
    if (!frame) return;
    switch (event.target.value as string) {
      case "plinth":
        frame.plinth = {
          height: 48,
          legs: true,
        };
        frame.basePlate = true;
        break;
      case "basePlate":
        frame.plinth = null;
        frame.basePlate = true;
        break;
      case "no":
        frame.plinth = null;
        frame.basePlate = false;
        break;
      default:
        break;
    }
    refresh();
    update({});
  };

  const getBackgroundType = () => {
    return dataModel.frame?.backgroundPlate?.type ?? "no";
  };

  const changeBackgroundType = (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    const frame = dataModel.frame;
    if (!frame) return;

    frame.backgroundPlate =
      event.target.value === "no"
        ? null
        : { type: event.target.value as BackgroundPlateTypes };

    refresh();
    update({});
  };

  const getOpenings = () =>
    (dataModel.frame?.content.filter(
      (elem) => elem.type === FrameInnerElementTypes.OPENING,
    ) || []) as FrameOpening[];

  const removeOpening = () => {
    if (dataModel.frame) {
      const openings = getOpenings();
      if (openings.filter((o) => o.locked).length === openings.length - 1)
        return;
      if (openings.length > 1) {
        const content = dataModel.frame.content;
        for (let i = content.length - 1; i >= 0; i--) {
          if (
            content[i].type === FrameInnerElementTypes.OPENING &&
            !(content[i] as any).locked
          ) {
            if (i !== content.length - 1) {
              dataModel.frame.content = [
                ...content.slice(0, i),
                ...content.slice(i + 2, content.length),
              ];
            } else {
              dataModel.frame.content = [...content.slice(0, i - 1)];
            }
            break;
          }
        }
        refresh();
        update({
          availableComponents: true,
          availableHoleHeights: true,
        });
      }
    }
  };

  const addOpening = () => {
    if (dataModel.frame) {
      const openings = getOpenings();
      if (openings.length >= 1) {
        const content = dataModel.frame.content;
        let index = -1;
        for (let i = content.length - 1; i >= 0; i--) {
          if (
            content[i].type === FrameInnerElementTypes.OPENING &&
            !(content[i] as any).locked
          ) {
            index = i;
            break;
          }
        }

        if (index < 0) {
          index = content.length >= 3 ? content.length - 3 : content.length - 1;
        }

        dataModel.frame.content = [
          ...content.slice(0, index + 1),
          {
            type: FrameInnerElementTypes.WALL_NORMAL,
          },
          {
            type: FrameInnerElementTypes.OPENING,
            locked: false,
            components: [],
            children: {},
            width:
              (content[index] as FrameOpening).width ||
              Math.ceil(dataModel.frame.width / openings.length),
          },
          ...content.slice(index + 1, content.length),
        ];

        refresh();
        update({
          availableComponents: true,
          availableHoleHeights: true,
        });
      }
    }
  };

  const removeDoor = () => {
    if (dataModel.doors) {
      if (dataModel.doors.slidingDoors.length > 1) {
        dataModel.doors.slidingDoors.splice(
          dataModel.doors.slidingDoors.length - 1,
        );
        refresh();
        update({});
      }
    }
  };

  const addDoor = () => {
    if (dataModel.doors) {
      const sd = dataModel.doors.slidingDoors;
      if (!sd.length) {
        sd.push({ width: 1, sectors: [] });
      } else {
        //clone last door
        const last_door = sd[sd.length - 1];
        const newDoor: SlidingDoor = {
          ...last_door,
          sectors: last_door.sectors.map((s) => ({ ...s })),
        };
        sd.push(newDoor);
      }
      refresh();
      update({});
    }
  };

  const copyDoor = (sourceIndex: number, targetIndex: number) => {
    if (dataModel.doors) {
      const sd = dataModel.doors.slidingDoors;

      //clone door
      const source_door = sd[sourceIndex];
      const target_door = sd[targetIndex];
      if (source_door && target_door) {
        target_door.sectors = source_door.sectors.map((s) => ({ ...s }));
        refresh();
        update({});
      }
    }
  };

  const hasFixedShelf = (index: number): boolean => {
    //TODO: this shoud be memoized list of opening indexes
    if (dataModel.frame && dataModel.frame.content[index]) {
      for (let component of (dataModel.frame.content[index] as FrameOpening)
        .components) {
        if (
          ["HYLLY_KIINTEA", "HYLLY_KIINTEA_PUOLIKAS"].includes(
            component.modelID,
          )
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const getOpeningComponents = (el: FrameOpening, childId: string | null) => {
    let components: FrameComponent[];
    if (childId) {
      if (!el.children) {
        el.children = {};
      }
      if (!el.children[childId]) {
        el.children[childId] = [];
      }
      components = el.children[childId];
    } else {
      components = el.components;
    }
    return components;
  };

  const addComponent = (
    contentIndex: number,
    childId: string | null,
    componentIndex: number | null,
    amount: number,
  ) => {
    if (dataModel.frame && dataModel.frame.content.length > contentIndex) {
      const opening = dataModel.frame.content[contentIndex] as FrameOpening;
      const availableHoles =
        dataState.availableHoleHeights &&
        dataState.availableHoleHeights[contentIndex];
      if (availableHoles && dataState.allComponents) {
        for (let i = 0; i < amount; i++) {
          const components = getOpeningComponents(opening, childId);

          if (components.length) {
            const prevComponent =
              componentIndex !== null
                ? components[componentIndex]
                : components[components.length - 1];

            const secondPrevComponent =
              componentIndex !== null
                ? components[componentIndex - 1]
                : components[components.length - 2];

            let componentHeightOffset;
            if (
              secondPrevComponent &&
              prevComponent.modelID === secondPrevComponent.modelID
            ) {
              //use previous holediff if two components ahead are like
              componentHeightOffset =
                prevComponent.holeHeight - secondPrevComponent.holeHeight;
            } else {
              const componentDef = dataState.allComponents.find(
                (c) => prevComponent.modelID === c.modelID,
              );

              componentHeightOffset = componentDef
                ? componentDef.minMarginTop + componentDef.minMarginBottom
                : 512;

              if (
                prevComponent.params &&
                prevComponent.params["extraHeightMm"]
              ) {
                componentHeightOffset += parseInt(
                  prevComponent.params["extraHeightMm"] as string,
                  10,
                );
              }
            }

            let holeHeight: number | undefined =
              prevComponent.holeHeight + componentHeightOffset;

            holeHeight = availableHoles.find(
              (hh: number) => hh >= (holeHeight as number),
            );

            if (!holeHeight) {
              holeHeight = availableHoles[availableHoles.length - 1];
            }

            components.push({
              ...prevComponent,
              params: prevComponent.params
                ? Object.keys(prevComponent.params).reduce(
                    (out: ComponentParams, paramName) => {
                      if (prevComponent.params)
                        out[paramName] = prevComponent.params[paramName];
                      return out;
                    },
                    {},
                  )
                : undefined,
              holeHeight,
            });
          } else if (
            dataState.availableHoleHeights &&
            dataState.availableComponents &&
            dataState.availableComponents[childId || contentIndex]?.length
          ) {
            const componentDef =
              dataState.availableComponents[childId || contentIndex].find(
                (c) => c.modelID === "HYLLY",
              ) || dataState.availableComponents[childId || contentIndex][0];

            if (componentDef) {
              components.push({
                holeHeight: availableHoles[0],
                modelID: componentDef.modelID,
                params: componentDef.params
                  ? Object.keys(componentDef.params).reduce(
                      (out: ComponentParams, paramName: string) => {
                        const param = componentDef.params[paramName];
                        out[paramName] = param.default;
                        return out;
                      },
                      {},
                    )
                  : undefined,
              });
            } else {
              alert("Aukkoon sopivia komponentteja ei löytynyt!");
            }
          }
        }
        refresh();
        update({});
      }
    }
  };

  const buildOpeningComponents = (
    el: FrameOpening,
    contentIndex: number,
    openingIndex: number,
    childId: string | null,
  ) => {
    //build listing in reverse order
    const components = getOpeningComponents(el, childId);

    return components.reduce((output: ReactElement[], component, index) => {
      const currentComponent = dataState.allComponents?.find(
        (c) => c.modelID === component.modelID,
      );
      const isAvailableComponent =
        !dataState.availableComponents ||
        (!!currentComponent &&
          !!dataState.availableComponents[childId || contentIndex]?.find(
            (c) => c.modelID === currentComponent.modelID,
          ));

      const elem = (
        <OpeningComponent
          key={`${index}_${component.holeHeight}`}
          title={`Elementti ${openingIndex}-${index + 1}`}
          onChange={(reorder: boolean = false) => {
            if (reorder) {
              components.sort((a, b) => a.holeHeight - b.holeHeight);
            }
            refresh();
            update({});
          }}
          onRemove={() => {
            components.splice(index, 1);
            refresh();
            update({});
          }}
          onAdd={() => {
            addComponent(contentIndex, childId, index, 1);
            components.sort((a, b) => a.holeHeight - b.holeHeight);
          }}
          component={component}
          contentIndex={contentIndex}
          index={index}
          availableComponents={
            dataState.availableComponents &&
            dataState.availableComponents[childId || contentIndex]
          }
          availableHoleHeights={
            (dataState.availableHoleHeights &&
              dataState.availableHoleHeights[contentIndex]) ??
            []
          }
          currentComponent={currentComponent}
          isAvailableComponent={isAvailableComponent}
          error={!isAvailableComponent}
          canEdit={canEdit}
        />
      );
      output.unshift(elem);
      return output;
    }, []);
  };

  const sectorExpansionChange = (drawDoors: boolean) => {
    if (
      dataModel?.doors &&
      dataModel.drawDoors !== drawDoors &&
      (drawDoors || dataModel.recessedDoors)
    ) {
      dataModel.drawDoors = drawDoors;
      refresh();
    }
  };

  const buildContentElements = () => {
    let elements: ReactElement[] = [];
    if (dataModel?.frame) {
      let openingIndex = 1;
      let innerWallIndex = 1;
      const openings = getOpenings();
      const canChangeWidth =
        openings.length >
        openings.filter((opening) => opening.locked).length + 1;
      for (let index = 0; index < dataModel.frame.content.length; index++) {
        const element: FrameInnerElements = dataModel.frame.content[index];
        const nameBase = `frame.content.${index}`;
        switch (element.type) {
          case FrameInnerElementTypes.OPENING:
            const opening = element as FrameOpening;
            elements.push(
              <SideBarSector
                onExpansionChange={(event, expanded) =>
                  expanded && sectorExpansionChange(!expanded)
                }
                title={`Aukko ${openingIndex}`}
                key={`A${index}`}
              >
                <SideBarSectorItem
                  label="Leveys"
                  name={`${nameBase}.width`}
                  disabled={!canEdit || (!opening.locked && !canChangeWidth)}
                  onLock={() => {
                    opening.locked = !opening.locked;
                    refresh();
                    update({});
                  }}
                  locked={opening.locked}
                  error={getError(errors, nameBase)}
                >
                  <FormControl component="fieldset">
                    <TextField
                      label="mm"
                      variant="outlined"
                      aria-label={`Aukon ${openingIndex} leveys`}
                      disabled={!canEdit || opening.locked || !canChangeWidth}
                      name={`${nameBase}.width`}
                      type="number"
                      value={opening.width}
                      onChange={(event) => {
                        opening.locked = true;
                        onDataChange<string, number>(event, {
                          transformer: (value) => parseInt(value || "0"),
                          beforeUpdate: () => {
                            opening.locked = false;
                          },
                          update: { availableComponents: true },
                        });
                      }}
                      inputProps={{ min: 50, max: 1200, step: 10 }}
                    />
                    <ButtonGroup fullWidth variant="text" color="primary">
                      {OPENING_WIDTH_PRESETS.map((preset_width) => (
                        <Button
                          disabled={
                            !canEdit || opening.locked || !canChangeWidth
                          }
                          onClick={() => {
                            opening.locked = true;
                            opening.width = preset_width;
                            refresh();
                            opening.locked = false;
                            update({ availableComponents: true });
                          }}
                          color="primary"
                          className="openingWidthPresetButton"
                          key={preset_width}
                        >
                          {preset_width}mm
                        </Button>
                      ))}
                    </ButtonGroup>
                  </FormControl>
                </SideBarSectorItem>
                {!hasFixedShelf(index) && (
                  <SideBarSectorItem>
                    <InfoText text="Muista kiinteät hyllyt" />
                  </SideBarSectorItem>
                )}
                <SideBarSectorItem>
                  <Button
                    disabled={!canEdit}
                    className="wideItem"
                    onClick={() => addComponent(index, null, null, 1)}
                    color="primary"
                  >
                    + Lisää elementti
                  </Button>
                  {/* <Button
                    disabled={!canEdit}
                    className="wideItem"
                    onClick={() => addComponent(index, null, null, 3)}
                    color="primary"
                  >
                    + 3
                  </Button> */}{" "}
                </SideBarSectorItem>
                {buildOpeningComponents(opening, index, openingIndex, null)}
              </SideBarSector>,
            );

            if (opening.children) {
              for (const openingChildId in opening.children) {
                elements.push(
                  <SideBarSector
                    onExpansionChange={(event, expanded) =>
                      expanded && sectorExpansionChange(!expanded)
                    }
                    title={`Aukko ${openingChildId}`}
                    key={`A${openingChildId}`}
                  >
                    <SideBarSectorItem>
                      <Button
                        disabled={!canEdit}
                        className="wideItem"
                        onClick={() =>
                          addComponent(index, openingChildId, null, 1)
                        }
                        color="primary"
                      >
                        + Lisää elementti
                      </Button>
                    </SideBarSectorItem>
                    {buildOpeningComponents(
                      opening,
                      index,
                      openingIndex,
                      openingChildId,
                    )}
                  </SideBarSector>,
                );
              }
            }
            openingIndex++;
            break;
          default:
            elements.push(
              <SideBarSector
                onExpansionChange={(event, expanded) =>
                  expanded && sectorExpansionChange(!expanded)
                }
                title={`Väliseinä ${innerWallIndex}`}
                key={`W${index}`}
              >
                <SideBarSectorItem label="Väliseinän tyyppi">
                  <FormControl variant="outlined">
                    <Select
                      disabled={!canEdit}
                      name={`${nameBase}.type`}
                      value={element.type ?? FrameInnerElementTypes.WALL_NORMAL}
                      onChange={(event) => {
                        element.type = event.target.value as
                          | FrameInnerElementTypes.WALL_NORMAL
                          | FrameInnerElementTypes.WALL_T_TYPE
                          | FrameInnerElementTypes.WALL_FRAME;

                        if (
                          element.type === FrameInnerElementTypes.WALL_T_TYPE
                        ) {
                          if (
                            !dataState.availableHoleHeights ||
                            !dataState.availableHoleHeights[index]
                          )
                            return;
                          const hh = dataState.availableHoleHeights[index];
                          (element as FramePartitionWallT).height =
                            hh[Math.ceil((hh.length - 1) * 0.8)];
                        } else {
                          delete (element as any).height;
                        }
                        refresh();
                        update({
                          availableComponents: true,
                          availableHoleHeights: true,
                        });
                      }}
                    >
                      <MenuItem
                        value={FrameInnerElementTypes.WALL_NORMAL}
                        key={1}
                      >
                        Välisivu
                      </MenuItem>
                      <MenuItem
                        value={FrameInnerElementTypes.WALL_FRAME}
                        key={2}
                      >
                        Tuplavälisivu
                      </MenuItem>
                      <MenuItem
                        value={FrameInnerElementTypes.WALL_T_TYPE}
                        key={3}
                      >
                        Vaakavälisivu
                      </MenuItem>
                    </Select>
                  </FormControl>
                </SideBarSectorItem>
                {dataState.availableHoleHeights &&
                  dataState.availableHoleHeights[index] &&
                  element.type === FrameInnerElementTypes.WALL_T_TYPE && (
                    <SideBarSectorItem label="Korkeus">
                      <FormControl variant="outlined">
                        <Select
                          disabled={!props.canEdit}
                          name={`wall_${index}_holeHeight`}
                          value={
                            (element as FramePartitionWallT).height || 1000
                          }
                          onChange={(event) => {
                            (element as FramePartitionWallT).height = parseInt(
                              event.target.value as string,
                            );
                            refresh();
                            update({ availableHoleHeights: true });
                          }}
                        >
                          {dataState.availableHoleHeights[index].reduce(
                            (out: ReactElement[], height, index) => {
                              out.unshift(
                                <MenuItem value={height} key={index}>
                                  {height} mm
                                </MenuItem>,
                              );
                              return out;
                            },
                            [],
                          )}
                        </Select>
                      </FormControl>
                    </SideBarSectorItem>
                  )}
              </SideBarSector>,
            );
            innerWallIndex++;
        }
      }
    }
    return elements;
  };

  const hasBackgroundplate = !!dataModel.frame?.backgroundPlate;
  const canSelectBackgorundPlateMaterial =
    hasBackgroundplate &&
    dataModel.frame?.backgroundPlate?.type !== BackgroundPlateTypes.OUTER_16;

  return (
    <>
      {!props.isTemplate && (
        <SideBarSector
          title={`Kaapiston Rakenne`}
          defaultExpanded={props.isNewProject}
        >
          <SideBarSectorItem label="Kaapiston tyyppi" name="type">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="frame type"
                name="frameType"
                value={getFrameType()}
                onChange={changeFrameType}
              >
                <FormControlLabel
                  value="recessedDoors"
                  control={<Radio color="primary" />}
                  label="Ovet rungon sisään"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="externalDoors"
                  control={<Radio color="primary" />}
                  label="Ovet rungon eteen"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="frame"
                  control={<Radio color="primary" />}
                  label="Vain runko"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="recessedDoorsFrame"
                  control={<Radio color="primary" />}
                  label="Vain runko ovivarauksella"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="doors"
                  control={<Radio color="primary" />}
                  label="Vain ovet"
                  disabled={!canEdit}
                />
              </RadioGroup>
            </FormControl>

            {dataModel.recessedDoors && (
              <InfoText text="Ovet rungon sisällä vähentää käyttösyvyyttä 90 mm" />
            )}
          </SideBarSectorItem>
        </SideBarSector>
      )}
      {dataModel.frame && hasFrame && (
        <SideBarSector
          onExpansionChange={(event, expanded) =>
            expanded && sectorExpansionChange(!expanded)
          }
          title={`Runko`}
        >
          <SideBarSectorItem label="Leveys" name="frame width">
            <FormControl component="fieldset">
              <TextField
                label="mm"
                variant="outlined"
                disabled={!canEdit}
                aria-label="frame width"
                name="frame.width"
                type="number"
                value={dataModel.frame.width}
                onChange={(event) =>
                  onDataChange<string, number>(event, {
                    transformer: positiveNumber,
                    update: { availableComponents: true },
                  })
                }
                inputProps={{ min: 500, max: 2800 * 5, step: 20 }}
              />
            </FormControl>
            {props.isTemplate && props.templateData && (
              <>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.minWidth = dataModel.frame?.width;
                      refresh();
                    }
                  }}
                >
                  Min [{props.templateData.minWidth ?? "NOT SET"}]
                </Button>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.maxWidth = dataModel.frame?.width;
                      refresh();
                    }
                  }}
                >
                  Max [{props.templateData.maxWidth ?? "NOT SET"}]
                </Button>
              </>
            )}
          </SideBarSectorItem>
          <SideBarSectorItem
            label="Korkeus"
            name="frame height"
            error={getError(errors, "frame.height")}
          >
            <FormControl component="fieldset">
              <TextField
                label="mm"
                variant="outlined"
                disabled={!canEdit}
                aria-label="frame height"
                name="frame.height"
                type="number"
                value={dataModel.frame.height}
                onChange={(event) =>
                  onDataChange<string, number>(event, {
                    transformer: positiveNumber,
                    update: {
                      availableComponents: true,
                      availableHoleHeights: true,
                    },
                  })
                }
                inputProps={{ min: 300, max: 2780, step: 20 }}
              />
            </FormControl>
            {props.isTemplate && props.templateData && (
              <>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.minHeight = dataModel.frame?.height;
                      refresh();
                    }
                  }}
                >
                  Min [{props.templateData.minHeight ?? "NOT SET"}]
                </Button>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.maxHeight = dataModel.frame?.height;
                      refresh();
                    }
                  }}
                >
                  Max [{props.templateData.maxHeight ?? "NOT SET"}]
                </Button>
              </>
            )}
          </SideBarSectorItem>
          <SideBarSectorItem
            label="Syvyys"
            name="frame depth"
            error={getError(errors, "frame.depth")}
          >
            <FormControl component="fieldset">
              <TextField
                label="mm"
                variant="outlined"
                disabled={!canEdit}
                aria-label="frame depth"
                name="frame.depth"
                type="number"
                value={dataModel.frame.depth}
                onChange={(event) =>
                  onDataChange<string, number>(event, {
                    transformer: positiveNumber,
                    update: { availableComponents: true },
                  })
                }
                inputProps={{ min: 200, max: 2800, step: 20 }}
              />
            </FormControl>
            {props.isTemplate && props.templateData && (
              <>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.minDepth = dataModel.frame?.depth;
                      refresh();
                    }
                  }}
                >
                  Min [{props.templateData.minDepth ?? "NOT SET"}]
                </Button>
                <Button
                  onClick={() => {
                    if (dataModel?.frame && props.templateData) {
                      props.templateData.maxDepth = dataModel.frame?.depth;
                      refresh();
                    }
                  }}
                >
                  Max [{props.templateData.maxDepth ?? "NOT SET"}]
                </Button>
              </>
            )}
            <InfoText text={`Käyttösyvyys: ${calculatedInnerDepth} mm`} />
          </SideBarSectorItem>
          {!props.isTemplate && dataState.availableFrameMaterials && (
            <SideBarSectorItem
              label="Runkomateriaali"
              name="frame mateial"
              error={getError(errors, "frame.materials.frame")}
            >
              <FormControl variant="outlined">
                <Select
                  disabled={!canEdit}
                  name="frame.materials.frame"
                  value={dataModel.frame.materials.frame ?? ""}
                  onChange={(event) => onDataChange<string, string>(event)}
                >
                  {(dataState.availableFrameMaterials.frame ?? []).map(
                    ({ name, materialID }, index) => (
                      <MenuItem value={materialID} key={index}>
                        {name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </SideBarSectorItem>
          )}
          {!props.isTemplate && dataState.availableFrameMaterials && (
            <SideBarSectorItem
              label="Väliseinämateriaali"
              name="frame inner material"
              error={getError(errors, "frame.materials.inner")}
            >
              <FormControl variant="outlined">
                <Select
                  disabled={!canEdit}
                  name="frame.materials.inner"
                  value={dataModel.frame.materials.inner ?? ""}
                  onChange={(event) => onDataChange<string, string>(event)}
                >
                  {(dataState.availableFrameMaterials.inner ?? []).map(
                    ({ name, materialID }, index) => (
                      <MenuItem value={materialID} key={index}>
                        {name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </SideBarSectorItem>
          )}
          {!props.isTemplate && dataState.availableFrameMaterials && (
            <SideBarSectorItem
              label="Hyllymateriaali"
              name="shelf material"
              error={getError(errors, "frame.materials.shelf")}
            >
              <FormControl variant="outlined">
                <Select
                  disabled={!canEdit}
                  name="frame.materials.shelf"
                  value={dataModel.frame.materials.shelf ?? ""}
                  onChange={(event) => onDataChange<string, string>(event)}
                >
                  {(dataState.availableFrameMaterials.shelf ?? []).map(
                    ({ name, materialID }, index) => (
                      <MenuItem value={materialID} key={index}>
                        {name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </SideBarSectorItem>
          )}
          <SideBarSectorItem label="Taustalevy" name="type">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="frameType"
                name="frameType"
                value={getBackgroundType()}
                onChange={changeBackgroundType}
              >
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="Ei"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value={BackgroundPlateTypes.INNER_3}
                  control={<Radio color="primary" />}
                  label="3 mm uralla"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value={BackgroundPlateTypes.OUTER_3}
                  control={<Radio color="primary" />}
                  label="3 mm ei uraa"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value={BackgroundPlateTypes.OUTER_16}
                  control={<Radio color="primary" />}
                  label="Runkomateriaali 16 mm"
                  disabled={!canEdit}
                />
              </RadioGroup>
            </FormControl>
            {dataModel.frame.backgroundPlate &&
              dataModel.frame.backgroundPlate.type ===
                BackgroundPlateTypes.OUTER_16 && (
                <InfoText text="Valinta vähentää käyttösyvyyttä 16 mm" />
              )}
            {dataModel.frame.backgroundPlate &&
              (dataModel.frame.backgroundPlate.type ===
                BackgroundPlateTypes.OUTER_3 ||
                dataModel.frame.backgroundPlate.type ===
                  BackgroundPlateTypes.INNER_3) && (
                <InfoText text="Valinta vähentää käyttösyvyyttä 3 mm" />
              )}
          </SideBarSectorItem>
          {dataState.availableFrameMaterials && hasBackgroundplate && (
            <SideBarSectorItem
              label="Taustalevyn materiaali"
              name="background plate material"
              error={getError(errors, "frame.materials.backgroundPlate")}
            >
              <FormControl variant="outlined">
                <Select
                  name="frame.materials.backgroundPlate"
                  value={dataModel.frame.materials.backgroundPlate ?? ""}
                  disabled={!canEdit || !canSelectBackgorundPlateMaterial}
                  onChange={(event) => onDataChange<string, string>(event)}
                >
                  {canSelectBackgorundPlateMaterial ? (
                    (
                      dataState.availableFrameMaterials.backgroundPlate ?? []
                    ).map(({ name, materialID }, index) => (
                      <MenuItem value={materialID} key={index}>
                        {name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      value={dataModel.frame.materials.backgroundPlate || ""}
                      key={-1}
                    >
                      Runkomateriaali
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </SideBarSectorItem>
          )}
          <SideBarSectorItem label="Pohjalevy" name="type">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="frameType"
                name="frameType"
                value={getBaseType()}
                onChange={changeBaseType}
              >
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="Ei"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="basePlate"
                  control={<Radio color="primary" />}
                  label="Pohjalevyllä"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="plinth"
                  control={<Radio color="primary" />}
                  label="Sokkeli"
                  disabled={!canEdit}
                />
              </RadioGroup>
              {dataModel.frame.plinth && (
                <TextField
                  label="mm"
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="name"
                  name="frame.plinth.height"
                  type="number"
                  value={dataModel.frame.plinth?.height}
                  onChange={(event) =>
                    onDataChange<string, number>(event, {
                      transformer: positiveNumber,
                    })
                  }
                  inputProps={{ min: 48, max: 200, step: 1 }}
                />
              )}
              {dataModel.frame.plinth && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!canEdit || dataModel.frame.plinth.height > 100}
                      checked={
                        dataModel.frame.plinth.legs &&
                        dataModel.frame.plinth.height <= 100
                      }
                      name="frame.plinth.legs"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => {
                        if (!dataModel.frame?.plinth) return;
                        dataModel.frame.plinth.legs = checked;
                        refresh();
                        update({});
                      }}
                      color="primary"
                    />
                  }
                  label="Säätöjalat"
                />
              )}
            </FormControl>
          </SideBarSectorItem>
          <SideBarSectorItem label="Kattolevy" name="type">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="frameType"
                name="frame.coverPlate"
                value={dataModel.frame.coverPlate ? "true" : "false"}
                onChange={(event) =>
                  onDataChange<string, boolean>(event, {
                    transformer: (v) => v === "true",
                  })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Kyllä"
                  disabled={!canEdit}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Ei"
                  disabled={!canEdit}
                />
              </RadioGroup>
            </FormControl>
          </SideBarSectorItem>
          <SideBarSectorItem label="Aukkojen määrä" name="type">
            <FormControl component="fieldset">
              <ButtonGroup fullWidth color="primary">
                <Button
                  disabled={!canEdit}
                  onClick={removeOpening}
                  className="buttonGroupButtonSmall"
                >
                  -
                </Button>
                <Button disabled={!canEdit}>{getOpenings().length}</Button>
                <Button
                  disabled={!canEdit}
                  onClick={addOpening}
                  className="buttonGroupButtonSmall"
                >
                  +
                </Button>
              </ButtonGroup>
            </FormControl>
          </SideBarSectorItem>
          <SideBarSectorItem
            label="Lisävalinnat"
            name="type"
            error={getError(errors, "frame.lightPlate")}
          >
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canEdit}
                    checked={dataModel.frame.lightPlate}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => {
                      if (!dataModel.frame) return;
                      dataModel.frame.lightPlate = checked;
                      refresh();
                      update({});
                    }}
                    name="frame.lightPlate"
                    color="primary"
                  />
                }
                label="Valolippa"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataModel.frame.lightPlateSwitch}
                    disabled={!canEdit || !dataModel.frame?.lightPlate}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => {
                      if (!dataModel.frame) return;
                      dataModel.frame.lightPlateSwitch = checked;
                      refresh();
                      update({});
                    }}
                    name="frame.lightPlateSwitch"
                    color="primary"
                  />
                }
                label="Valolipan langaton katkaisija"
              />
            </FormControl>
          </SideBarSectorItem>
        </SideBarSector>
      )}
      {dataModel.frame && hasFrame && buildContentElements()}
      {dataModel.doors && hasDoors && (
        <SideBarSector
          onExpansionChange={(event, expanded) =>
            expanded && sectorExpansionChange(expanded)
          }
          title={`Ovet`}
        >
          {!dataModel.recessedDoors && (
            <SideBarSectorItem
              label="Asennuskorkeus"
              name="frame height"
              error={getError(errors, "doors.height")}
            >
              <FormControl component="fieldset">
                <TextField
                  label="mm"
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="Ovien asennuskorkeus"
                  name="doors.height"
                  type="number"
                  value={dataModel.doors.height}
                  onChange={(event) =>
                    onDataChange<string, number>(event, {
                      transformer: positiveNumber,
                    })
                  }
                  inputProps={{ min: 500, max: 2800, step: 100 }}
                />
              </FormControl>
            </SideBarSectorItem>
          )}

          {!dataModel.recessedDoors && (
            <SideBarSectorItem
              label="Asennusvälin yläleveys"
              name="frame height"
            >
              <FormControl component="fieldset">
                <TextField
                  label="mm"
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="Ovien ylälistan leveys"
                  name="doors.topWidth"
                  type="number"
                  value={dataModel.doors.topWidth}
                  onChange={(event) =>
                    onDataChange<string, number>(event, {
                      transformer: positiveNumber,
                    })
                  }
                  inputProps={{ min: 500, max: 5000, step: 100 }}
                />
              </FormControl>
            </SideBarSectorItem>
          )}

          {!dataModel.recessedDoors && (
            <SideBarSectorItem
              label="Asennusvälin alaleveys"
              name="frame height"
            >
              <FormControl component="fieldset">
                <TextField
                  label="mm"
                  variant="outlined"
                  disabled={!canEdit}
                  aria-label="Ovien alalistan leveyt"
                  name="doors.bottomWidth"
                  type="number"
                  value={dataModel.doors.bottomWidth}
                  onChange={(event) =>
                    onDataChange<string, number>(event, {
                      transformer: positiveNumber,
                    })
                  }
                  inputProps={{ min: 500, max: 5000, step: 100 }}
                />
              </FormControl>
            </SideBarSectorItem>
          )}
          {dataState.availableDoorFrames && (
            <SideBarSectorItem label="Kehysten materiaali" name="frame mateial">
              <FormControl variant="outlined">
                <Select
                  disabled={!canEdit}
                  name="doors.frame"
                  value={
                    dataState.availableDoorFrames
                      ? dataState.availableDoorFrames.findIndex(
                          (df) =>
                            df.frameType === dataModel.doors?.frameType &&
                            df.materialID === dataModel.doors?.frameMaterial,
                        )
                      : ""
                  }
                  onChange={(event) => {
                    if (!dataModel.doors || !dataState.availableDoorFrames)
                      return;
                    const selection =
                      dataState.availableDoorFrames[
                        parseInt(event.target.value as string)
                      ];
                    dataModel.doors.frameType = selection.frameType;
                    dataModel.doors.frameMaterial = selection.materialID;
                    refresh();
                    update({});
                  }}
                >
                  {dataState.availableDoorFrames.map(({ name }, index) => (
                    <MenuItem value={index} key={index}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </SideBarSectorItem>
          )}

          {dataModel.doors.frameType &&
            ["A7", "A25"].includes(dataModel.doors.frameType) && [
              <SideBarSectorItem label="Hidastimien määrä">
                <ButtonGroup fullWidth color="primary">
                  <Button
                    disabled={!canEdit}
                    onClick={() => {
                      if (dataModel?.doors) {
                        dataModel.doors.dampers = Math.max(
                          0,
                          Math.min(4, (dataModel.doors.dampers || 0) - 1),
                        );
                        refresh();
                        update({});
                      }
                    }}
                    className="buttonGroupButtonSmall"
                  >
                    -
                  </Button>
                  <Button disabled={!canEdit}>
                    {dataModel.doors.dampers || 0}
                  </Button>
                  <Button
                    disabled={!canEdit}
                    onClick={() => {
                      if (dataModel?.doors) {
                        dataModel.doors.dampers = Math.max(
                          0,
                          Math.min(4, (dataModel.doors.dampers || 0) + 1),
                        );
                        refresh();
                        update({});
                      }
                    }}
                    className="buttonGroupButtonSmall"
                  >
                    +
                  </Button>
                </ButtonGroup>
              </SideBarSectorItem>,
              <SideBarSectorItem label="Vetimet">
                <Checkbox
                  disabled={!canEdit}
                  checked={!!dataModel.doors?.handles}
                  name="frame.plinth.legs"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => {
                    if (dataModel.doors) {
                      dataModel.doors.handles = checked;
                      refresh();
                      update({});
                    }
                  }}
                  color="primary"
                />
              </SideBarSectorItem>,
            ]}

          <SideBarSectorItem label="Ovien määrä">
            <FormControl component="fieldset">
              <ButtonGroup fullWidth color="primary">
                <Button
                  disabled={!canEdit}
                  onClick={removeDoor}
                  className="buttonGroupButtonSmall"
                >
                  -
                </Button>
                <Button disabled={!canEdit}>
                  {dataModel.doors.slidingDoors.length}
                </Button>
                <Button
                  disabled={!canEdit}
                  onClick={addDoor}
                  className="buttonGroupButtonSmall"
                >
                  +
                </Button>
              </ButtonGroup>
            </FormControl>
          </SideBarSectorItem>
          <SideBarSectorItem label="Ovien leveys">
            <Typography className="textInfo">
              {dataModel.doors.slidingDoors.length
                ? dataModel.doors.slidingDoors[0].width
                : "-"}
              mm
            </Typography>
          </SideBarSectorItem>

          {dataModel.doors.slidingDoors.map((sd, index) => {
            const currentSelection = sd.sectors.some((s) => s.percent == null)
              ? "Vapaa"
              : sd.sectors
                  .map((s) => `${(s.percent || 0).toFixed(1)}%`)
                  .join(" - ");

            return [
              <SideBarSectorItem
                label={`Ovi ${index + 1}`}
                key={`D${index}_${dataModel.doors?.slidingDoors.length}`}
                fatDivider={true}
              >
                {" "}
                <FormControl variant="outlined">
                  <Select
                    disabled={!canEdit}
                    name="doors.frame"
                    value={currentSelection}
                    onChange={(event) => {
                      const selection = parseInt(event.target.value as string);
                      if (isNaN(selection)) return;
                      const data = DoorPresets[selection];
                      if (data && data.values.length) {
                        for (let i = 0; i < data.values.length; i++) {
                          if (sd.sectors.length <= i) {
                            sd.sectors.push({
                              direction: DoorMaterialDirection.VERTICAL,
                              height: 100,
                              percent: data.values[i],
                              locked: false,
                              material: "",
                            });
                          } else {
                            sd.sectors[i].percent = data.values[i];
                          }
                        }
                        sd.sectors.splice(data.values.length);
                        refresh();
                        update({});
                      }
                    }}
                  >
                    <MenuItem disabled value={currentSelection} key="-1">
                      {currentSelection}
                    </MenuItem>
                    {DoorPresets.map(({ name }, index) => (
                      <MenuItem value={index} key={index}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SideBarSectorItem>,
              <SideBarSectorItem
                label={`Kopioi ovi`}
                lightLabel={true}
                key={`CD${index}_${dataModel.doors?.slidingDoors.length}`}
              >
                {" "}
                <FormControl variant="outlined">
                  <Select
                    disabled={!canEdit}
                    name="doors.frame.copy"
                    value=""
                    onChange={(event) => {
                      const selection = parseInt(event.target.value as string);
                      if (isNaN(selection)) return;
                      copyDoor(index, selection);
                    }}
                  >
                    <MenuItem disabled value="" key="-1"></MenuItem>
                    {dataModel.doors?.slidingDoors.map((_door, di) => {
                      if (index !== di) {
                        return (
                          <MenuItem value={di} key={di}>
                            Oveen {di + 1}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              </SideBarSectorItem>,
              ...(!dataState.availableDoorSectorMaterials
                ? []
                : sd.sectors.reduce((out: ReactNode[], sector, si) => {
                    const nameBase = `doors.slidingDoors.${index}.sectors.${si}`;
                    out.unshift(
                      ...[
                        <SideBarSectorItem
                          label={`Korkeus ${index + 1} - ${si + 1}`}
                          lightLabel
                          key={`K${index + 1}_${si + 1}`}
                          error={getError(errors, `${nameBase}.height`)}
                          onLock={() => {
                            sector.locked = !sector.locked;
                            refresh();
                            update({});
                          }}
                          locked={sector.locked}
                        >
                          <TextField
                            label="mm"
                            fullWidth
                            variant="outlined"
                            aria-label={`Sektorin ${si + 1} korkeus`}
                            disabled={!canEdit || sector.locked}
                            name={`${nameBase}.height`}
                            type="number"
                            value={Math.round(sector.height)}
                            onChange={(event) => {
                              sector.locked = true;
                              sd.sectors.forEach((s) => (s.percent = null));
                              onDataChange<string, number>(event, {
                                transformer: (value) => parseInt(value || "0"),
                                beforeUpdate: () => {
                                  sector.locked = false;
                                },
                              });
                            }}
                            inputProps={{ min: 20, max: 2780, step: 10 }}
                          />
                        </SideBarSectorItem>,
                        <SideBarSectorItem
                          label={`Materiaali ${index + 1} - ${si + 1}`}
                          lightLabel
                          key={`M${index + 1}_${si + 1}`}
                          error={getError(errors, `${nameBase}.material`)}
                        >
                          <FormControl variant="outlined">
                            <Select
                              disabled={!canEdit}
                              name="doorsector"
                              value={sector.material}
                              onChange={(event) => {
                                sector.material = event.target.value as string;
                                refresh();
                                update({});
                              }}
                            >
                              {dataState.availableDoorSectorMaterials?.map(
                                ({ name, materialID }, index) => (
                                  <MenuItem value={materialID} key={index}>
                                    {name}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </SideBarSectorItem>,
                        <SideBarSectorItem
                          label={`Suunta ${index + 1} - ${si + 1}`}
                          key={`S${index + 1}_${si + 1}`}
                          lightLabel
                          error={getError(errors, `${nameBase}.direction`)}
                        >
                          <FormControl variant="outlined">
                            <Select
                              disabled={!canEdit}
                              name="doorsector"
                              value={
                                sector.direction ||
                                DoorMaterialDirection.VERTICAL
                              }
                              onChange={(event) => {
                                sector.direction = event.target
                                  .value as DoorMaterialDirection;
                                refresh();
                                update({});
                              }}
                            >
                              {Object.values(DoorMaterialDirection)?.map(
                                (value, index) => (
                                  <MenuItem value={value} key={index}>
                                    {value === DoorMaterialDirection.VERTICAL
                                      ? "Pysty"
                                      : "Vaaka"}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </SideBarSectorItem>,
                      ],
                    );
                    return out;
                  }, [])),
            ];
          })}
        </SideBarSector>
      )}
    </>
  );
};

function positiveNumber(value: string): number {
  const parsed = parseInt(value);
  //engine handles NaN values.
  return isNaN(parsed) || parsed >= 0 ? parsed : 0;
}

function getError(errors: EngineError[], path: string): undefined | string {
  return errors.find((error) => (error as DataModelError).path === path)
    ?.message;
}
